<template>
  <mdb-container class="p-0" fluid>
    <AppNavigation>
      <mdb-container class="my-2">
        <validation-observer slim ref="changePasswordForm">
          <mdb-container>
            <mdb-row>
              <mdb-col md="6" offsetMd="3">
                <validation-provider
                  name="Current Password"
                  rules="required"
                  v-slot="{ errors, touched }"
                  slim
                >
                  <mdb-input
                    type="password"
                    label="Current Password"
                    v-model="current"
                    :invalidFeedback="errors[0]"
                    :isValid="!!!errors[0]"
                    :validation="touched"
                  />
                </validation-provider>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col md="6" offsetMd="3">
                <validation-provider
                  name="New Password"
                  rules="required"
                  v-slot="{ errors, touched }"
                  slim
                >
                  <mdb-input
                    type="password"
                    label="New Password"
                    v-model="password"
                    :invalidFeedback="errors[0]"
                    :isValid="!!!errors[0]"
                    :validation="touched"
                  />
                </validation-provider>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col md="6" offsetMd="3">
                <validation-provider
                  name="Confirm Password"
                  rules="required"
                  v-slot="{ errors, touched }"
                  slim
                >
                  <mdb-input
                    type="password"
                    label="Confirm Password"
                    v-model="confirmation"
                    :invalidFeedback="errors[0]"
                    :isValid="!!!errors[0]"
                    :validation="touched"
                  />
                </validation-provider>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col col="12" class="d-flex justify-content-center">
                <mdb-btn color="primary" @click="changePassword" size="sm">
                  {{ $t('change_password') }}
                </mdb-btn>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col col="12" class="justify-content-center d-flex">
                <a class="small" @click="goBack">{{ $t('back') }}</a>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </validation-observer>
      </mdb-container>
    </AppNavigation>
    <Loading v-if="loading" />
  </mdb-container>
</template>

<script>
// @ is an alias to /src
import { mdbContainer, mdbRow, mdbCol, mdbInput, mdbBtn } from 'mdbvue'
import AppNavigation from '../../components/admin/AppNavigation.vue'
import { ajax } from '../../helpers/ajax'
import Loading from '../../components/util/Loading.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'change_password',
  components: {
    mdbContainer,
    mdbBtn,
    AppNavigation,
    mdbRow,
    mdbCol,
    mdbInput,
    Loading,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    current: '',
    password: '',
    confirmation: '',
    loading: false,
  }),
  methods: {
    async changePassword() {
      const success = await this.$refs.changePasswordForm.validate()
      if (!success) return
      this.loading = true
      await ajax(
        {
          url: 'user/change_password',
          data: {
            password: this.password,
            current_password: this.current,
            password_confirmation: this.confirmation,
          },
          isShowError: true,
          success: () => {
            this.$notify.success({
              message: 'Password Updated Successfully',
              position: 'top right',
              timeOut: 5000,
            })
            this.$router.replace({ name: 'admin_dashboard' })
          },
        },
        this
      )
      this.loading = false
    },
    goBack() {
      this.$router.replace({ name: 'admin_dashboard' })
    },
  },
}
</script>
