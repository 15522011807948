<template>
  <mdb-container fluid class="p-0">
    <LandingLayout>
      <validation-observer v-if="step == 0" slim ref="resetPasswordForm">
        <mdb-row class="my-5">
          <mdb-col md="4" offsetMd="4">
            <mdb-card>
              <mdb-card-header> {{ $t('reset_password') }} </mdb-card-header>
              <mdb-card-body>
                <div>
                  {{ $t('enter_new_password_below') }}
                </div>
                <mdb-row>
                  <mdb-col col="12">
                    <validation-provider
                      :name="$t('password')"
                      rules="required|min_length:8"
                      v-slot="{ errors, touched }"
                      slim
                    >
                      <mdb-input
                        :label="$t('password')"
                        size="sm"
                        type="password"
                        v-model="form.password"
                        :invalidFeedback="errors[0]"
                        :isValid="!!!errors[0]"
                        :validation="touched"
                      />
                    </validation-provider>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col col="12">
                    <validation-provider
                      :name="$t('password_confirmation')"
                      :rules="`required|confirmed:@${$t('password')}`"
                      v-slot="{ errors, touched }"
                      slim
                    >
                      <mdb-input
                        :label="$t('password_confirmation')"
                        size="sm"
                        type="password"
                        v-model="form.password_confirmation"
                        :invalidFeedback="errors[0]"
                        :isValid="!!!errors[0]"
                        :validation="touched"
                      />
                    </validation-provider>
                  </mdb-col>
                </mdb-row>
                <div>
                  <mdb-btn
                    @click="resetPassword"
                    block
                    color="primary"
                    size="sm"
                    >{{ $t('reset_password') }}</mdb-btn
                  >
                </div>
                <Loading v-if="loading" />
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </validation-observer>
      <mdb-row v-if="step == 1" class="my-5">
        <mdb-col md="6" offsetMd="3">
          <h3 class="h3-responsive text-center">
            {{ $t('reset_password_success') }}
          </h3>
          <p class="text-center">
            {{ $t('redirecting_in_seconds', { seconds: this.timer }) }}
          </p>
        </mdb-col>
      </mdb-row>
    </LandingLayout>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbCol,
  mdbRow,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbInput,
  mdbBtn,
} from 'mdbvue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Loading from '../components/util/Loading.vue'
import LandingLayout from '../components/LandingLayout.vue'

export default {
  name: 'reset_password',
  components: {
    mdbContainer,
    mdbCardHeader,
    mdbCol,
    mdbRow,
    mdbCard,
    mdbCardBody,
    ValidationProvider,
    ValidationObserver,
    mdbInput,
    mdbBtn,
    Loading,
    LandingLayout,
  },
  data: () => ({
    login_register_modal: false,
    form: {
      password: '',
      password_confirmation: '',
      username: '',
      otp_id: 0,
      otp: '',
    },
    loading: true,
    step: 0,
    timer: 5,
    timer_interval: null,
  }),
  methods: {
    loginRegister() {
      this.login_register_modal = true
    },
    closeModal() {
      this.login_register_modal = false
    },
    async verifyCodeUsername() {
      this.loading = true
      this.$ajax(
        {
          url: 'auth/verification_reset_password',
          data: { username: this.form.username, otp: this.form.otp },
          success: (otp_result) => {
            this.loading = false
            this.form.otp_id = otp_result.data
          },
          fail: () => this.$router.replace({ name: 'landing' }),
        },
        this
      )
    },
    async resetPassword() {
      const success = await this.$refs.resetPasswordForm.validate()
      if (!success) {
        return false
      }
      this.loading = true
      this.$ajax(
        {
          url: 'auth/complete_forgot_password',
          data: this.form,
          success: () => {
            this.$notify.success({
              message: this.$t('reset_password_success'),
              position: 'top right',
              timeOut: 3000,
            })
            this.step = 1
            this.loginRegister()

            this.startCountDown()
          },
          isShowError: true,
          complete: () => (this.loading = false),
        },
        this
      )
    },
    startCountDown() {
      clearInterval(this.timer_interval)
      this.timer_interval = setInterval(() => {
        if (this.timer == 0) {
          clearInterval(this.timer_interval)
          this.$router.replace({ name: 'landing' })
          return
        }
        this.timer = this.timer - 1
      }, 1000)
    },
  },
  mounted: function () {
    const { code, username } = this.$route.query
    if (!code || !username) this.$router.replace({ name: 'landing' })
    this.form.otp = code
    this.form.username = username
    this.verifyCodeUsername()
  },
}
</script>
