<template>
  <mdb-container fluid class="p-0">
    <navbar-public @login="loginRegister" />
    <mdb-row class="pt-5 mt-5">
      <mdb-col md="4" offsetMd="4">
        <mdb-view :src="require('@/assets/rip.jpg')" alt="verified image">
          <a>
            <mdb-mask overlay="white-slight"></mdb-mask>
          </a>
        </mdb-view>
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col md="4" offsetMd="4">
        <mdb-file-input sm btnColor="primary" />
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col md="4" offsetMd="4" class="text-center">
        <mdb-btn color="primary" size="sm">Submit</mdb-btn>
      </mdb-col>
    </mdb-row>
    <landing-footer />
    <login-register-modal-vue
      :visible="login_register_modal"
      :close="closeModal"
    />
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbView,
  mdbMask,
  mdbFileInput,
  mdbBtn,
} from 'mdbvue'
import NavbarPublic from '../components/NavbarPublic.vue'
import LandingFooter from '../components/LandingFooter.vue'
import LoginRegisterModalVue from '../components/user/LoginRegisterModal.vue'

export default {
  components: {
    mdbContainer,
    NavbarPublic,
    LandingFooter,
    mdbRow,
    mdbCol,
    mdbView,
    mdbFileInput,
    mdbBtn,
    mdbMask,
    LoginRegisterModalVue,
  },
  data: () => ({
    login_register_modal: false,
  }),
  methods: {
    loginRegister() {
      this.login_register_modal = true
    },
    closeModal() {
      this.login_register_modal = false
    },
  },
}
</script>
