<template>
  <mdb-container fluid class="p-0">
    <navbar-user-vue />
    <mdb-container>
      <mdb-row class="mb-2">
        <mdb-col col="2" offset="1">
          <mdb-btn color="secondary" @click="routeBack" size="sm">
            <mdb-icon icon="arrow-left" /> {{ $t('back') }}
          </mdb-btn>
        </mdb-col>
      </mdb-row>
      <vue-document-editor
        ref="documentEditorContainer"
        :content.sync="content_text"
        :editable="false"
      />
    </mdb-container>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbIcon, mdbBtn } from 'mdbvue'
import VueDocumentEditor from 'vue-document-editor'
import NavbarUserVue from '../../components/user/NavbarUser.vue'

export default {
  components: {
    mdbContainer,
    VueDocumentEditor,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    NavbarUserVue,
  },
  data: () => ({
    content_text: [],
    id: 0,
    loading: false,
  }),
  methods: {
    routeBack() {
      this.$router.back()
    },
    getData() {
      this.loading = true
      this.$ajax(
        {
          url: `user/get_released_content_text?id=${this.id}`,
          method: 'GET',
          success: (result) => {
            this.content_text = JSON.parse(result.data.text)
            // this.$refs.documentEditorContainer.restrictEditing = true
          },
          isShowError: true,
          complete: () => (this.loading = false),
        },
        this
      )
    },
  },
  mounted: function () {
    const { id } = this.$route.params
    this.id = id
    if (!this.id) this.routeBack()
    this.getData()
    // this.recipient = { ...this.$route.params.recipient }
    // this.user = { ...this.$route.params.user }
    // this.content_text = JSON.parse(this.recipient.text)
  },
}
</script>
