<template>
  <AppNavigationVue>
    <mdb-container fluid>
      <h1 class="h1-responsive">
        Admins
        <mdb-btn
          @click="admin_modal = true"
          color="success"
          size="sm"
          class="my-0"
          ><mdb-icon icon="plus"
        /></mdb-btn>
      </h1>
      <hr />
      <mdb-row>
        <mdb-col col="12">
          <mdb-tbl responsive striped>
            <mdb-tbl-head>
              <tr>
                <th>#</th>
                <th>Username/Name</th>
                <th>Phone Number</th>
                <th>Last Login</th>
                <th>Action</th>
              </tr>
            </mdb-tbl-head>
            <mdb-tbl-body>
              <tr v-for="(user, index) in users" :key="user.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  <div>{{ user.username }}</div>
                  <div>{{ user.name }}</div>
                </td>
                <td>{{ user.phone_number }}</td>
                <td>
                  {{ momentFormat(user.last_login) }}
                </td>
                <td>
                  <mdb-btn
                    @click="editUser(user)"
                    color="primary"
                    size="sm"
                    class="my-0"
                    ><mdb-icon far icon="edit" /> Edit</mdb-btn
                  >
                  <mdb-btn
                    @click="openConfirmationModal(user)"
                    color="danger"
                    size="sm"
                    class="my-0"
                    ><mdb-icon far icon="trash-alt" /> Delete</mdb-btn
                  >
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <Admin
      :visible="admin_modal"
      :close="closeModal"
      :focus_user="focus_user"
      @getData="getData()"
    />
    <ConfirmationModalVue
      :visible="confirmation_modal"
      :close="closeModal"
      :submit="deleteAdmin"
    />
  </AppNavigationVue>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbTbl,
  mdbTblBody,
  mdbTblHead,
  mdbIcon,
} from 'mdbvue'
import { ajax } from '../../helpers/ajax'
import AppNavigationVue from '../../components/admin/AppNavigation.vue'
import { datetimeFormat } from '../../helpers/moment'
import Admin from '../../components/admin/Admin.vue'
import ConfirmationModalVue from '../../components/util/ConfirmationModal.vue'

export default {
  components: {
    mdbContainer,
    AppNavigationVue,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbIcon,
    Admin,
    ConfirmationModalVue,
  },
  data: () => ({
    users: [],
    loading: false,
    admin_modal: false,
    focus_user: null,
    confirmation_modal: false,
  }),
  methods: {
    closeModal() {
      this.focus_user = null
      this.confirmation_modal = false
      this.admin_modal = false
    },
    async getData() {
      this.loading = true
      ajax(
        {
          url: 'admin/get_admins',
          method: 'GET',
          success: (result) => {
            this.users = result.data
          },
        },
        this
      )
      this.loading = false
    },
    async deleteAdmin() {
      this.loading = true
      ajax(
        {
          url: 'admin/delete_admin',
          data: { id: this.focus_user.id },
          method: 'put',
          success: () => {
            this.getData()
          },
        },
        this
      )
      this.loading = false
    },
    editUser(user) {
      this.focus_user = user
      this.admin_modal = true
    },
    momentFormat(datetime) {
      return datetimeFormat(datetime)
    },
    openConfirmationModal(user) {
      this.focus_user = user
      this.confirmation_modal = true
    },
  },
  mounted: function () {
    this.getData()
  },
}
</script>
