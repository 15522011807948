<template>
  <mdb-container fluid class="p-0">
    <LandingLayout>
      <validation-observer v-if="step == 0" slim ref="forgotPasswordForm">
        <mdb-row class="my-5">
          <mdb-col md="4" offsetMd="4">
            <mdb-card>
              <mdb-card-header> {{ $t('reset_password') }} </mdb-card-header>
              <mdb-card-body>
                <div>
                  {{ $t('reset_password_desc') }}
                </div>
                <validation-provider
                  :name="$t('email')"
                  rules="required|email"
                  v-slot="{ errors, touched }"
                  slim
                >
                  <mdb-input
                    :label="$t('email')"
                    size="sm"
                    v-model="form.email"
                    :invalidFeedback="errors[0]"
                    :isValid="!!!errors[0]"
                    :validation="touched"
                  />
                </validation-provider>
                <div>
                  <mdb-btn
                    @click="requestOTP"
                    block
                    color="primary"
                    size="sm"
                    >{{ $t('reset_password') }}</mdb-btn
                  >
                </div>
                <div class="d-flex flex-center mt-4">
                  <a class="small" @click="routeBack">{{ $t('back') }}</a>
                </div>
                <Loading v-if="loading" />
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </validation-observer>
      <mdb-row v-if="step == 1" class="my-5">
        <mdb-col md="6" offsetMd="3">
          <h3 class="h3-responsive">{{ $t('reset_password_sent') }}</h3>
          <p>{{ $t('reset_password_sent_desc') }}</p>
        </mdb-col>
      </mdb-row>
    </LandingLayout>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbCol,
  mdbRow,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbInput,
  mdbBtn,
} from 'mdbvue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Loading from '../components/util/Loading.vue'
import LandingLayout from '../components/LandingLayout.vue'

export default {
  name: 'forgot_password',
  components: {
    mdbContainer,
    mdbCardHeader,
    mdbCol,
    mdbRow,
    mdbCard,
    mdbCardBody,
    ValidationProvider,
    ValidationObserver,
    mdbInput,
    mdbBtn,
    Loading,
    LandingLayout,
  },
  data: () => ({
    login_register_modal: false,
    form: {
      email: '',
    },
    loading: false,
    step: 0,
  }),
  methods: {
    loginRegister() {
      this.login_register_modal = true
    },
    closeModal() {
      this.login_register_modal = false
    },
    async requestOTP() {
      const success = await this.$refs.forgotPasswordForm.validate()
      if (!success) {
        return false
      }
      this.loading = true
      this.$ajax(
        {
          url: 'auth/request_forgot_password_otp',
          data: { username: this.form.email },
          success: () => {
            this.step = 1
          },
          isShowError: true,
          complete: () => (this.loading = false),
        },
        this
      )
    },
    routeBack() {
      this.$router.back()
    },
  },
}
</script>
