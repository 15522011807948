<template>
  <mdb-modal @show="show" :show="visible" @close="closeModal">
    <mdb-modal-header>
      <mdb-modal-title>{{
        recipient ? $t('edit_recipient') : $t('add_new_recipient')
      }}</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <validation-observer slim ref="recipientForm">
        <mdb-container fluid>
          <mdb-row>
            <mdb-col col="12">
              <validation-provider
                :name="$t('email')"
                rules="required|email"
                v-slot="{ errors, touched }"
                slim
              >
                <mdb-input
                  :label="$t('email')"
                  size="sm"
                  v-model="form.email"
                  :invalidFeedback="errors[0]"
                  :isValid="!!!errors[0]"
                  :validation="touched"
                />
              </validation-provider>
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col col="12">
              <validation-provider
                :name="$t('relationship')"
                rules="required"
                v-slot="{ errors, touched }"
                slim
              >
                <select
                  v-model="form.relationship"
                  class="browser-default custom-select"
                  :invalidFeedback="errors[0]"
                  :isValid="!!!errors[0]"
                  :validation="touched"
                >
                  <option value="" selected disabled>
                    {{ $t('select_relationship') }}
                  </option>
                  <option
                    v-for="option in relationshipOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </validation-provider>
            </mdb-col>
          </mdb-row>
        </mdb-container>
      </validation-observer>
    </mdb-modal-body>
    <mdb-modal-footer>
      <mdb-btn color="success" @click="submitRecipient" size="sm"
        >Submit</mdb-btn
      >
    </mdb-modal-footer>
    <Loading v-if="loading" />
  </mdb-modal>
</template>

<script>
import {
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbRow,
  mdbCol,
  mdbContainer,
  mdbInput,
  mdbModalFooter,
  mdbBtn,
} from 'mdbvue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ajax } from '../../helpers/ajax'
import { relationshipOptions } from '../../helpers/constants'
import Loading from '../util/Loading.vue'
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    recipient: {
      type: Object,
      default: null,
    },
  },
  components: {
    mdbModal,
    mdbModalHeader,
    mdbRow,
    mdbCol,
    mdbContainer,
    mdbInput,
    mdbModalTitle,
    mdbModalBody,
    ValidationProvider,
    ValidationObserver,
    mdbModalFooter,
    mdbBtn,
    Loading,
  },
  data() {
    return {
      loading: false,
      form: {
        email: '',
        relationship: '',
      },
      relationshipOptions: relationshipOptions,
    }
  },
  methods: {
    show() {
      if (this.recipient) {
        this.form = { ...this.recipient }
      }
    },
    closeModal() {
      this.form = {
        email: '',
        relationship: '',
      }
      this.close()
    },
    async submitRecipient() {
      const success = await this.$refs.recipientForm.validate()
      if (!success) return
      this.loading = true
      await ajax(
        {
          url: 'user/submit_recipient',
          data: { ...this.form },
          isShowError: true,
          success: (result) => {
            this.$store.dispatch('updateRecipient', result.data)
            this.$notify.success({
              message: this.$t('recipient_submitted_successfully'),
              position: 'top right',
              timeOut: 3000,
            })
            this.closeModal()
          },
        },
        this
      )
      this.loading = false
    },
  },
}
</script>
