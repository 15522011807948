<template>
  <mdb-container fluid class="p-0">
    <LandingLayout>
      <mdb-row>
        <mdb-col class="col-md-4 d-none d-md-block">
          <div
            :class="`img-${image_index}`"
            v-for="image_index in [1, 2, 3, 4, 5]"
            v-bind:key="image_index"
          >
            <img
              :src="require(`@/assets/landing_${image_index}.jpg`)"
              class="landing-img"
            />
          </div>
        </mdb-col>
        <mdb-col class="col-sm-4 d-none d-sm-block d-md-none">
          <div
            :class="`small-img`"
            v-for="image_index in [1, 2, 3, 4, 5]"
            v-bind:key="image_index"
          >
            <img
              :src="require(`@/assets/landing_${image_index}.jpg`)"
              class="landing-img-small"
            />
          </div>
        </mdb-col>
        <mdb-col class="col-md-8 container-text-right">
          <p class="text-desc mt-2">
            Nothing is certain in this world, except two - taxes and death.
            Often we hear advices to live like you are dying tomorrow.
          </p>

          <p class="text-desc">
            Tim McGraw sang to Live Like You Were Dying. The lyric started with
            him saying he was in his early forties with a lot of life before
            him, when a moment came that stopped him on a dime. He spent the
            next days talking about the options and the sweet times.
          </p>

          <p class="text-desc">
            Ronan Keating also sang what If Tomorrow Never Comes? Will she know
            how much I loved her? And if his time on earth were through, and she
            must face this world without him. Will the love he gave her in the
            past, be enough to last, if tomorrow never comes?
          </p>

          <p class="text-desc">
            Ronan Keating songs resonates with me. As a father of two naughty
            kids, there are so many things that I wanted to tell them and to
            teach them. Sometimes, at night, I do lie awake and watch them
            sleeping. They are still young to understand the world. I hope I
            pointed them in the right direction.
          </p>

          <p class="text-desc">
            Many things happens in the world unexpectedly. MH17 and MH370,
            mishaps, the pandemic, natural disaster or even a minor mishap at
            home. We could be at the wrong place at the wrong time.
          </p>

          <p class="text-desc">
            If one day, I left suddenly, and my children must face this world
            without me. I wonder if the love I gave them in the past is going to
            be enough to last. What about all the things that I wanted to tell
            them and teach them? Will they know how much I loved them?
          </p>

          <p class="text-desc">
            AfterLove is created for you to leave your message to your loved
            ones. A message to give them a closure. To give them an assurance
            that it is ok. And that they should continue to live the best they
            can.
          </p>

          <p class="text-desc">
            There are a lot of things that money can buy, but money cannot buy
            memories.
          </p>

          <div class="d-flex flex-row justify-content-end">
            <mdb-btn color="primary" @click="loginRegister" v-if="!user"
              >{{ $t('login') }} / {{ $t('register') }}</mdb-btn
            >
            <mdb-btn color="primary" @click="routeDashboard" v-if="user"
              >{{ $t('dashboard') }}
            </mdb-btn>
          </div>
        </mdb-col>
      </mdb-row>
    </LandingLayout>
    <login-register-modal-vue
      :visible="login_register_modal"
      :close="closeModal"
    />
  </mdb-container>
</template>

<script>
import LoginRegisterModalVue from '../components/user/LoginRegisterModal.vue'
import { mdbContainer, mdbRow, mdbBtn, mdbCol } from 'mdbvue'
import LandingLayout from '../components/LandingLayout.vue'
import { mapState } from 'vuex'

export default {
  name: 'landing',
  components: {
    mdbBtn,
    mdbRow,
    LoginRegisterModalVue,
    mdbContainer,
    LandingLayout,
    mdbCol,
  },
  data: () => ({
    login_register_modal: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    loginRegister() {
      this.login_register_modal = true
    },
    closeModal() {
      this.login_register_modal = false
    },
    routeDashboard() {
      this.$router.replace('dashboard')
    },
  },
}
</script>

<style scoped>
.landing-img {
  height: auto;
  width: 225px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}
.landing-img-small {
  height: auto;
  width: 125px;
  border-radius: 2.5px;
  border: 0.5px solid #eeeeee;
}
.img-1 {
  position: absolute;
  top: 5px;
  left: 15px;
  z-index: 1;
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.img-2 {
  position: absolute;
  top: 120px;
  left: 60px;
  z-index: 2;
  -ms-transform: rotate(4deg);
  transform: rotate(4deg);
}
.img-3 {
  position: absolute;
  top: 240px;
  left: 35px;
  z-index: 3;
  -ms-transform: rotate(-2deg);
  transform: rotate(-2deg);
}
.img-4 {
  position: absolute;
  top: 350px;
  left: 80px;
  z-index: 4;
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
}
.img-5 {
  position: absolute;
  top: 450px;
  z-index: 5;
  left: 25px;
}

.small-img {
  margin-bottom: 1rem;
}

.main-container {
  overflow: hidden;
}

.text-desc {
  font-size: 0.9rem;
}

.container-text-right {
  z-index: 500;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 5px;
}
</style>
