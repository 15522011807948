import moment from 'moment'

export const getSecondDuration = (date_end) => {
  const end = moment(date_end).utcOffset('+8:00')
  const start = moment(new Date())
  const duration = moment.duration(end.diff(start))
  return Math.floor(duration.asSeconds())
}

export const datetimeFormat = (datetime) => {
  return moment(datetime, 'YYYY-MM-DD h:mm:ss').format('DD/MM/YYYY h:mm a')
}
