import Vue from 'vue'
import Router from 'vue-router'
import Landing from './views/Landing.vue'
import PrivacyPolicy from './views/PrivacyPolicy.vue'
import TermsAndConditions from './views/TermAndConditions.vue'
import ContentEditor from './views/user/ContentEditor.vue'
import ChangePassword from './views/user/ChangePassword.vue'
import Dashboard from './views/user/Dashboard.vue'
import Profile from './views/user/Profile.vue'
import Verification from './views/Verification.vue'
import Rip from './views/Rip.vue'
import AdminLogin from './views/admin/Login.vue'
import Error404 from './views/Error404.vue'
import ForgotPassword from './views/ForgotPassword.vue'
import ResetPassword from './views/ResetPassword.vue'
import ReleasedDocument from './views/user/ReleasedDocument.vue'

import AdminDashboard from './views/admin/AdminDashboard.vue'
import AdminChangePassword from './views/admin/ChangePassword.vue'
import Admins from './views/admin/Admins.vue'
import TrusteeSubmit from './views/admin/TrusteeSubmit.vue'
import RecipientText from './views/admin/RecipientText.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing,
    },
    {
      path: '/privacy_policy',
      name: 'privacy_policy',
      component: PrivacyPolicy,
    },
    {
      path: '/terms_conditions',
      name: 'terms_conditions',
      component: TermsAndConditions,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/content_editor',
      name: 'content_editor',
      component: ContentEditor,
    },
    {
      path: '/change_password',
      name: 'change_password',
      component: ChangePassword,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/verification/:type/:verification_code',
      name: 'verification',
      component: Verification,
    },
    {
      path: '/recipient_rip_verification',
      name: 'recipient_rip_verification',
      component: Rip,
    },
    {
      path: '/admin/login',
      name: 'admin_login',
      component: AdminLogin,
    },
    {
      path: '/admin/dashboard',
      name: 'admin_dashboard',
      component: AdminDashboard,
    },
    {
      path: '/admin/change_password',
      name: 'admin_change_password',
      component: AdminChangePassword,
    },
    {
      path: '/admin/admins',
      name: 'admin_admins',
      component: Admins,
    },
    {
      path: '/admin/recipient_text',
      name: 'admin_recipient_text',
      component: RecipientText,
    },
    {
      path: '/admin/trustee_submit',
      name: 'trustee_submit',
      component: TrusteeSubmit,
    },
    {
      path: '/forgot_password',
      name: 'forgot_password',
      component: ForgotPassword,
    },
    {
      path: '/released_document',
      name: 'released_document',
      component: ReleasedDocument,
    },
    {
      path: '/reset_password',
      name: 'reset_password',
      component: ResetPassword,
    },
    {
      path: '/*',
      name: 'error_404',
      component: Error404,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
