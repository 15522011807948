import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import { ajax } from './helpers/ajax'
var ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    access_token: null,
    user: null,
    recipients: [],
    is_able_add_recipient: true,
    trustees: [],
    is_able_add_trustee: true,
    recipient_of: [],
    trustee_of: [],
  },
  mutations: {
    setAccessToken(state, payload) {
      state.access_token = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
    setRecipients(state, payload) {
      state.recipients = payload.results
      state.is_able_add_recipient = payload.is_able_add
      state.recipient_of = payload.recipient_of
    },
    setTrustees(state, payload) {
      state.trustees = payload.results
      state.is_able_add_trustee = payload.is_able_add
      state.trustee_of = payload.trustee_of
    },
    resetState(state) {
      const initial_state = {
        access_token: null,
        user: null,
        recipients: [],
        trustees: [],
      }
      Object.assign(state, initial_state)
    },
    updateRecipient(state, payload) {
      const index = state.recipients.findIndex(
        (recipient) => recipient.id == payload.id
      )
      if (index >= 0) {
        const recipients = [...state.recipients]
        recipients[index] = payload
        state.recipients = recipients
      } else {
        state.recipients = [...state.recipients, payload]
      }
    },
    updateTrustee(state, payload) {
      const index = state.trustees.findIndex(
        (trustee) => trustee.id == payload.id
      )
      if (index >= 0) {
        const trustees = [...state.trustee]
        trustees[index] = payload
        state.trustees = trustees
      } else {
        state.trustees = [...state.trustees, payload]
      }
    },
  },
  actions: {
    setAccessToken(context, payload) {
      context.commit('setAccessToken', payload)
    },
    setUser(context, payload) {
      context.commit('setUser', payload)
    },
    updateRecipient(context, payload) {
      context.commit('updateRecipient', payload)
    },
    updateTrustee(context, payload) {
      context.commit('updateTrustee', payload)
    },
    getRecipients(context, payload) {
      ajax(
        {
          url: 'user/get_recipients',
          isShowError: true,
          method: 'GET',
          success: (result) => {
            context.commit('setRecipients', result.data)
          },
        },
        payload
      )
    },
    getTrustees(context, payload) {
      ajax(
        {
          url: 'user/get_trustees',
          isShowError: true,
          method: 'GET',
          success: (result) => {
            context.commit('setTrustees', result.data)
          },
        },
        payload
      )
    },
    resetState(context) {
      context.commit('resetState')
    },
  },
})
