<template>
  <mdb-container fluid class="p-0">
    <navbar-user-vue />
    <mdb-container class="my-3">
      <mdb-row>
        <mdb-col md="8">
          <mdb-card class="mb-5">
            <mdb-card-body>
              <mdb-row>
                <mdb-col col="8" class="d-flex flex-row align-items-center">
                  <h4 class="h4-responsive m-0">{{ $t('my_recipients') }}</h4>
                </mdb-col>
                <mdb-col col="4" class="d-flex justify-content-end">
                  <mdb-btn
                    @click="show_recipient_modal = true"
                    color="primary"
                    size="sm"
                    :disabled="!is_able_add_recipient"
                  >
                    <mdb-icon icon="plus" />
                    {{ $t('recipient') }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
              <hr />
              <mdb-row v-if="recipients.length == 0">
                <mdb-col col="4" offset="4">
                  <img
                    :src="require('../../assets/empty.jpg')"
                    class="img-fluid"
                  />
                  <p class="text-center small">
                    {{ $t('nothing_here_recipient') }}
                  </p>
                </mdb-col>
              </mdb-row>
              <mdb-tbl hover v-if="recipients.length > 0">
                <mdb-tbl-head>
                  <tr>
                    <th>#</th>
                    <th>Email</th>
                    <th>Relationship</th>
                    <th class="d-flex flex-row justify-content-end">Action</th>
                  </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                  <tr
                    v-for="(recipient, index) in recipients"
                    :key="recipient.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ recipient.email }} &nbsp;
                      <mdb-tooltip
                        trigger="hover"
                        :options="{ placement: 'top' }"
                      >
                        <span slot="tip">{{
                          recipient.is_verified
                            ? $t('verified')
                            : $t('resend_verification')
                        }}</span>
                        <mdb-icon
                          @click.native="resendRecipientMail(recipient)"
                          :color="recipient.is_verified ? 'success' : 'grey'"
                          :icon="
                            recipient.is_verified ? 'check-circle' : 'envelope'
                          "
                          slot="reference"
                        />
                      </mdb-tooltip>
                    </td>
                    <td>{{ $t(recipient.relationship) }}</td>
                    <td class="d-flex flex-row justify-content-end">
                      <mdb-tooltip
                        trigger="hover"
                        :options="{ placement: 'top' }"
                      >
                        <span slot="tip">{{ $t('message') }}</span>
                        <mdb-btn
                          color="info"
                          slot="reference"
                          @click="openDocument(recipient)"
                          size="sm"
                        >
                          <mdb-icon far icon="file-alt" />
                        </mdb-btn>
                      </mdb-tooltip>
                      <mdb-tooltip
                        trigger="hover"
                        :options="{ placement: 'top' }"
                      >
                        <span slot="tip">{{ $t('delete') }}</span>
                        <mdb-btn
                          slot="reference"
                          @click="deleteRecipient(recipient)"
                          color="danger"
                          size="sm"
                        >
                          <mdb-icon far icon="trash-alt" />
                        </mdb-btn>
                      </mdb-tooltip>
                    </td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-card-body>
          </mdb-card>
          <mdb-card class="mb-5">
            <mdb-card-body>
              <mdb-row>
                <mdb-col col="8" class="d-flex flex-row align-items-center">
                  <h4 class="h4-responsive m-0">{{ $t('my_trustees') }}</h4>
                </mdb-col>
                <mdb-col col="4" class="d-flex justify-content-end">
                  <mdb-btn
                    @click="show_trustee_modal = true"
                    color="primary"
                    size="sm"
                    :disabled="!is_able_add_trustee"
                  >
                    <mdb-icon icon="plus" />
                    {{ $t('trustee') }}
                  </mdb-btn>
                </mdb-col>
              </mdb-row>
              <hr />
              <mdb-row v-if="trustees.length == 0">
                <mdb-col col="4" offset="4">
                  <img
                    :src="require('../../assets/empty.jpg')"
                    class="img-fluid"
                  />
                  <p class="text-center small">
                    {{ $t('nothing_here_trustee') }}
                  </p>
                </mdb-col>
              </mdb-row>
              <mdb-tbl hover v-if="trustees.length > 0">
                <mdb-tbl-head>
                  <tr>
                    <th>#</th>
                    <th>Email</th>
                    <th>Relationship</th>
                    <th class="d-flex flex-row justify-content-end">Action</th>
                  </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                  <tr v-for="(trustee, index) in trustees" :key="trustee.id">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ trustee.email }} &nbsp;
                      <mdb-tooltip
                        trigger="hover"
                        :options="{ placement: 'top' }"
                      >
                        <span slot="tip">{{
                          trustee.is_verified
                            ? $t('verified')
                            : $t('resend_verification')
                        }}</span>
                        <mdb-icon
                          @click.native="resendTrusteeMail(trustee)"
                          :color="trustee.is_verified ? 'success' : 'grey'"
                          :icon="
                            trustee.is_verified ? 'check-circle' : 'envelope'
                          "
                          slot="reference"
                        />
                      </mdb-tooltip>
                    </td>
                    <td>{{ $t(trustee.relationship) }}</td>
                    <td class="d-flex flex-row justify-content-end">
                      <mdb-btn
                        @click="deleteTrustee(trustee)"
                        color="danger"
                        size="sm"
                      >
                        <mdb-icon far icon="trash-alt" />
                      </mdb-btn>
                    </td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="4">
          <mdb-card class="mb-5">
            <mdb-card-header>
              {{ $t('my_profile') }}
            </mdb-card-header>
            <mdb-card-body>
              <mdb-row>
                <mdb-col col="6" class="text-title">{{ $t('name') }}</mdb-col>
                <mdb-col col="6" class="text-desc"
                  >{{ user.given_name }} {{ user.surname }}</mdb-col
                >
              </mdb-row>
              <mdb-row>
                <mdb-col col="6" class="text-title">{{
                  $t('phone_number')
                }}</mdb-col>
                <mdb-col col="6" class="text-desc">{{
                  user.phone_number
                }}</mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col col="6" class="text-title">{{ $t('dob') }}</mdb-col>
                <mdb-col col="6" class="text-desc">{{ user.dob }}</mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
          <mdb-card v-if="trustee_of.length > 0">
            <mdb-card-header>
              {{ $t('trustee_of') }}
            </mdb-card-header>
            <mdb-card-body>
              <mdb-row
                v-for="(each, index) in trustee_of"
                v-bind:key="each + index"
                class="mb-3"
              >
                <mdb-col col="12">
                  <div class="text-desc">
                    {{ each.user.given_name }} {{ each.user.surname }}
                  </div>
                  <div v-if="!each.attachment">
                    <mdb-tooltip
                      trigger="hover"
                      :options="{ placement: 'top' }"
                    >
                      <span slot="tip">{{ $t('upload_proof') }}</span>
                      <mdb-btn
                        color="primary"
                        slot="reference"
                        @click="showUploadModal(each)"
                        size="sm"
                      >
                        <mdb-icon color="white" icon="file-upload" />
                        {{ $t('upload_proof') }}
                      </mdb-btn>
                    </mdb-tooltip>
                  </div>

                  <div v-if="each.attachment" class="small grey-text">
                    Uploaded at: {{ each.upload_at }}
                  </div>
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <RecipientModal
      :recipient="focus_recipient"
      :visible="show_recipient_modal"
      :close="closeModal"
    />
    <TrusteeModal
      :trustee="focus_trustee"
      :visible="show_trustee_modal"
      :close="closeModal"
    />
    <ConfirmationModal
      :visible="show_confirmation_modal"
      :close="closeModal"
      :submit="confirmDelete"
    />
    <UploadProofModal
      :visible="show_upload_modal"
      :close="closeModal"
      :focus_trustee="focus_trustee"
      @reload="getData"
    />
    <Loading v-if="loading" />
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbCard,
  mdbCardBody,
  mdbTooltip,
  mdbCardHeader,
} from 'mdbvue'
import NavbarUserVue from '../../components/user/NavbarUser.vue'
import RecipientModal from '../../components/user/RecipientModal.vue'
import TrusteeModal from '../../components/user/TrusteeModal.vue'
import ConfirmationModal from '../../components/util/ConfirmationModal.vue'
import Loading from '../../components/util/Loading.vue'
import { mapState } from 'vuex'
import { ajax } from '../../helpers/ajax'
import UploadProofModal from '../../components/user/UploadProof.vue'

export default {
  components: {
    NavbarUserVue,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    RecipientModal,
    TrusteeModal,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbTooltip,
    mdbCard,
    mdbCardBody,
    Loading,
    ConfirmationModal,
    mdbCardHeader,
    UploadProofModal,
  },
  data: () => ({
    show_recipient_modal: false,
    show_trustee_modal: false,
    loading: false,
    tooltip_placement: { placement: 'top' },
    focus_recipient: null,
    focus_trustee: null,
    show_confirmation_modal: false,
    show_upload_modal: false,
    focus_delete: {
      id: null,
      type: '',
    },
  }),
  computed: {
    ...mapState({
      recipients: (state) => state.recipients,
      trustees: (state) => state.trustees,
      is_able_add_recipient: (state) => state.is_able_add_recipient,
      is_able_add_trustee: (state) => state.is_able_add_trustee,
      recipient_of: (state) => state.recipient_of,
      trustee_of: (state) => state.trustee_of,
      user: (state) => state.user,
    }),
  },
  methods: {
    closeModal() {
      this.show_recipient_modal = false
      this.show_trustee_modal = false
      this.show_confirmation_modal = false
      this.show_upload_modal = false
      this.focus_recipient = null
      this.focus_trustee = null
    },
    openDocument(recipient) {
      this.$router.push({
        name: 'content_editor',
        params: { recipient_id: recipient.id },
      })
    },
    editRecipient(recipient) {
      this.focus_recipient = recipient
      this.show_recipient_modal = true
    },
    deleteRecipient(recipient) {
      this.focus_delete = {
        id: recipient.id,
        type: 'recipient',
      }
      this.show_confirmation_modal = true
    },
    editTrustee(trustee) {
      this.focus_trustee = trustee
      this.show_trustee_modal = true
    },
    deleteTrustee(trustee) {
      this.focus_delete = {
        id: trustee.id,
        type: 'trustee',
      }
      this.show_confirmation_modal = true
    },
    showUploadModal(trustee) {
      this.focus_trustee = trustee
      this.show_upload_modal = true
    },
    viewMessage(recipient) {
      this.$router.push({ name: 'released_document', params: { ...recipient } })
    },
    confirmDelete() {
      let url =
        this.focus_delete.type == 'recipient'
          ? 'user/delete_recipient'
          : 'user/delete_trustee'
      this.loading = true
      ajax(
        {
          url: url,
          data: { id: this.focus_delete.id },
          isShowError: true,
          success: () => {
            this.focus_delete.type == 'recipient'
              ? this.$store.dispatch('getRecipients', this)
              : this.$store.dispatch('getTrustees', this)
            this.$notify.success({
              message:
                this.focus_delete.type == 'recipient'
                  ? this.$t('recipient_deleted')
                  : this.$t('trustee_deleted'),
              position: 'top right',
              timeOut: 3000,
            })
          },
        },
        this
      )
      this.loading = false
    },
    async resendRecipientMail(recipient) {
      if (recipient.is_verified === 1) return
      this.loading = true
      await ajax(
        {
          url: 'user/resend_recipient_verification',
          data: { id: recipient.id },
          success: (result) => {
            if (result.data == true) {
              this.$notify.success({
                message: this.$t('resend_verification_success'),
                position: 'top right',
                timeOut: 3000,
              })
            } else {
              this.$notify.info({
                message: this.$t('resend_verification_minutes', {
                  minutes: result.data,
                }),
                position: 'top right',
                timeOut: 3000,
              })
            }
          },
          isShowError: true,
        },
        this
      )
      this.loading = false
    },
    async resendTrusteeMail(trustee) {
      if (trustee.is_verified === 1) return
      this.loading = true
      await ajax(
        {
          url: 'user/resend_trustee_verification',
          data: { id: trustee.id },
          success: (result) => {
            if (result.data == true) {
              this.$notify.success({
                message: this.$t('resend_verification_success'),
                position: 'top right',
                timeOut: 3000,
              })
            } else {
              this.$notify.info({
                message: this.$t('resend_verification_minutes', {
                  minutes: result.data,
                }),
                position: 'top right',
                timeOut: 3000,
              })
            }
          },
          isShowError: true,
        },
        this
      )
      this.loading = false
    },
    getData() {
      this.$store.dispatch('getRecipients', this)
      this.$store.dispatch('getTrustees', this)
    },
  },
  mounted: function () {
    console.log(this.$store.state)
    this.getData()
  },
}
</script>

<style scoped>
.text-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  color: #2e2e2e;
}

.text-title {
  font-size: 0.8rem;
  color: #424242;
}
</style>
