<template>
  <mdb-container class="p-0" fluid>
    <navbar-user-vue />
    <mdb-container class="my-2">
      <validation-observer slim ref="profileForm">
        <mdb-row>
          <mdb-col md="4" offsetMd="4">
            <validation-provider
              :name="$t('name')"
              rules="required"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-input
                :label="$t('name')"
                v-model="form.name"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col md="4" offsetMd="4">
            <validation-provider
              :name="$t('phone_number')"
              rules="required"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-input
                :label="$t('phone_number')"
                v-model="form.phone_number"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col md="4" offsetMd="4">
            <validation-provider
              :name="$t('national_id')"
              rules="required"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-input
                :label="$t('national_id')"
                v-model="form.national_id"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
        <mdb-row class="mb-3">
          <mdb-col col="12" class="d-flex justify-content-center">
            <mdb-btn color="primary" @click="submitProfile" size="sm">
              {{ $t('submit') }}
            </mdb-btn>
          </mdb-col>
        </mdb-row>
        <mdb-row class="mb-3">
          <mdb-col col="12" class="justify-content-center d-flex">
            <a class="small" @click="goBack">{{ $t('back') }}</a>
          </mdb-col>
        </mdb-row>
      </validation-observer>
    </mdb-container>
    <Loading v-if="loading" />
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbInput, mdbBtn } from 'mdbvue'
import NavbarUserVue from '../../components/user/NavbarUser.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ajax } from '../../helpers/ajax'
import Loading from '../../components/util/Loading.vue'

export default {
  components: {
    mdbContainer,
    NavbarUserVue,
    mdbRow,
    mdbCol,
    ValidationProvider,
    ValidationObserver,
    mdbInput,
    Loading,
    mdbBtn,
  },
  data: () => ({
    loading: false,
    form: {
      name: '',
      phone_number: '',
      national_id: '',
    },
  }),
  methods: {
    async submitProfile() {
      const success = await this.$refs.profileForm.validate()
      if (!success) return
      this.loading = true
      await ajax(
        {
          url: 'user/update_profile',
          data: { ...this.form },
          isShowError: true,
          success: (res) => {
            this.$notify.success({
              message: 'Profile Updated Successfully',
              position: 'top right',
              timeOut: 5000,
            })
            this.$store.dispatch('setUser', res.data)
            this.$router.replace({ name: 'dashboard' })
          },
        },
        this
      )
      this.loading = false
    },
    goBack() {
      this.$router.replace({ name: 'dashboard' })
    },
  },
  mounted: function () {
    this.form = {
      name: this.$store.state.user.name,
      phone_number: this.$store.state.user.phone_number,
      national_id: this.$store.state.user.national_id,
    }
  },
}
</script>
