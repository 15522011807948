<template>
  <mdb-modal :show="visible" @close="closeModal">
    <mdb-modal-body>
      <mdb-tabs
        :active="index"
        pills
        color="primary"
        fill
        justify
        @activeTab="switchTab"
        :links="[{ text: $t('login') }, { text: $t('register') }]"
      />
      <Login
        v-if="index == 0"
        @loading="setLoading"
        @register="index = 1"
        @close="closeModal"
        :email="email"
        :preventNavigate="preventNavigate"
      />
      <Register
        v-if="index == 1"
        @loading="setLoading"
        :preventNavigate="preventNavigate"
        :email="email"
        @close="closeModal"
      />
      <Loading v-if="loading" />
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import { mdbModal, mdbModalBody, mdbTabs } from 'mdbvue'
import Loading from '../util/Loading.vue'
import Login from './Login.vue'
import Register from './Register.vue'
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    preventNavigate: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: '',
    },
  },
  components: {
    mdbModal,
    mdbModalBody,
    mdbTabs,
    Loading,
    Login,
    Register,
  },
  data() {
    return {
      loading: false,
      index: 0,
    }
  },
  methods: {
    closeModal() {
      this.close()
    },
    switchTab(index) {
      this.index = index
    },
    setLoading(loading) {
      this.loading = loading
    },
  },
}
</script>
