<template>
  <div id="full-screen-loading">
    <mdb-spinner color="blue" />
  </div>
</template>

<script>
import { mdbSpinner } from 'mdbvue'
export default {
  name: 'PageLoader',
  components: {
    mdbSpinner,
  },
}
</script>

<style scoped>
#full-screen-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>
