<template>
  <mdb-container fluid class="p-0">
    <navbar-public-vue />
    <mdb-container>
      <div class="row mt-5">
        <div class="col-md-12">
          <h1 class="h1 text-center my-5">Hano Terms and Conditions</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 my-5">
          <p class="text-left">
            Below are the Terms and Conditions for use of Hano Tech. Please read
            these carefully. If you need to contact us regarding any aspect of
            the following terms of use of our website, please contact us on the
            following email address - hanodeveloper@gmail.com.
          </p>
          <p class="text-left">
            By accessing the content of Hano Tech ( hereafter referred to as
            website ) you agree to the terms and conditions set out herein and
            also accept our
            <a href="/privacy_policy" target="_blank">Privacy Policy</a>. If you
            do not agree to any of the terms and conditions you should not
            continue to use the Website and leave immediately.
          </p>
          <p class="text-left">
            You agree that you shall not use the website for any illegal
            purposes, and that you will respect all applicable laws and
            regulations.
          </p>
          <p class="text-left">
            You agree not to use the website in a way that may impair the
            performance, corrupt or manipulate the content or information
            available on the website or reduce the overall functionality of the
            website.
          </p>
          <p class="text-left">
            You agree not to compromise the security of the website or attempt
            to gain access to secured areas of the website or attempt to access
            any sensitive information you may believe exist on the website or
            server where it is hosted.
          </p>
          <p class="text-left">
            You agree to be fully responsible for any claim, expense, losses,
            liability, costs including legal fees incurred by us arising from
            any infringement of the terms and conditions in this agreement and
            to which you will have agreed if you continue to use the website.
          </p>
          <p class="text-left">
            The reproduction, distribution in any method whether online or
            offline is strictly prohibited. The work on the website and the
            images, logos, text and other such information is the property of
            Hano Tech ( unless otherwise stated ).
          </p>
          <h4 class="h4 mt-5">Disclaimer</h4>
          <p class="text-left">
            Though we strive to be completely accurate in the information that
            is presented on our site, and attempt to keep it as up to date as
            possible, in some cases, some of the information you find on the
            website may be slightly outdated.
          </p>
          <p class="text-left">
            Hano Tech reserves the right to make any modifications or
            corrections to the information you find on the website at any time
            without notice.
          </p>
          <p class="text-left">
            We reserve the right to make changes and to revise the above
            mentioned Terms and Conditions of use.
          </p>
        </div>
      </div>
    </mdb-container>
    <landing-footer-vue />
  </mdb-container>
</template>

<script>
import NavbarPublicVue from '../components/NavbarPublic.vue'
import LandingFooterVue from '../components/LandingFooter.vue'
import { mdbContainer } from 'mdbvue'
export default {
  components: {
    NavbarPublicVue,
    LandingFooterVue,
    mdbContainer,
  },
}
</script>
