<template>
  <AppNavigation>
    <mdb-container>
      <mdb-row class="mb-2">
        <mdb-col col="2" offset="1">
          <mdb-btn color="secondary" @click="routeBack" size="sm">
            <mdb-icon icon="arrow-left" /> {{ $t('back') }}
          </mdb-btn>
        </mdb-col>
      </mdb-row>
      <mdb-row class="mb-2">
        <mdb-col offset="2" col="4">
          <mdb-card>
            <mdb-card-body>
              <h4 class="h4-responsive">User</h4>
              <div>Email: {{ user.username }}</div>
              <div>Name: {{ user.name }}</div>
              <div>Phone Number: {{ user.phone_number }}</div>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col col="4">
          <mdb-card>
            <mdb-card-body>
              <h4 class="h4-responsive">Recipient</h4>
              <div>Email: {{ recipient.email }}</div>
              <div>Name: {{ recipient.name }}</div>
              <div>Phone Number: {{ recipient.phone_number }}</div>
              <div>Relationship: {{ recipient.relationship }}</div>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <vue-document-editor :content.sync="content_text" />
    </mdb-container>
  </AppNavigation>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbCard,
  mdbCardBody,
} from 'mdbvue'
import VueDocumentEditor from 'vue-document-editor'
import AppNavigation from '../../components/admin/AppNavigation.vue'

export default {
  components: {
    mdbContainer,
    VueDocumentEditor,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbCard,
    mdbCardBody,
    AppNavigation,
  },
  data: () => ({
    content_text: [],
    recipient: {},
    user: {},
  }),
  methods: {
    routeBack() {
      this.$router.back()
    },
  },
  mounted: function () {
    this.recipient = { ...this.$route.params.recipient }
    this.user = { ...this.$route.params.user }
    this.content_text = JSON.parse(this.recipient.text)
  },
}
</script>
