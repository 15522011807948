<template>
  <mdb-navbar light color="grey lighten-5" scrolling>
    <mdb-container>
      <mdb-navbar-brand @click.native="navigateDashboard">
        <img
          :src="require('@/assets/logo_horizontal_1024.png')"
          style="height: 25px; width: 100px"
        />
      </mdb-navbar-brand>
      <mdb-navbar-toggler>
        <mdb-navbar-nav right>
          <mdb-dropdown tag="li" class="nav-item">
            <mdb-dropdown-toggle tag="a" navLink slot="toggle" waves-fixed>
              <mdb-icon icon="user" />
              {{ user.username }}
            </mdb-dropdown-toggle>
            <mdb-dropdown-menu>
              <mdb-dropdown-item @click="profile"> Profile </mdb-dropdown-item>
              <mdb-dropdown-item @click="changePassword">
                Change Password
              </mdb-dropdown-item>
              <mdb-dropdown-item @click="show_confirmation_modal = true">
                Logout
              </mdb-dropdown-item>
            </mdb-dropdown-menu>
          </mdb-dropdown>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-container>
    <ConfirmationModalVue
      :visible="show_confirmation_modal"
      :close="closeModal"
      :submit="logout"
    />
  </mdb-navbar>
</template>

<script>
import {
  mdbContainer,
  mdbNavbar,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavbarBrand,
  mdbDropdown,
  mdbDropdownToggle,
  mdbDropdownMenu,
  mdbDropdownItem,
  mdbIcon,
} from 'mdbvue'
import { mapState } from 'vuex'
import { ajax } from '../../helpers/ajax'
import ConfirmationModalVue from '../util/ConfirmationModal.vue'
export default {
  name: 'landing',
  components: {
    mdbContainer,
    mdbNavbar,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
    mdbNavbarBrand,
    mdbIcon,
    ConfirmationModalVue,
  },
  data: () => ({
    show_confirmation_modal: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    closeModal() {
      this.show_confirmation_modal = false
    },
    changePassword() {
      this.$router.push({ name: 'change_password' })
    },
    logout() {
      ajax(
        {
          url: 'user/logout',
          success: () => {
            this.$store.dispatch('resetState')
            this.$router.replace({ name: 'landing' })
          },
        },
        this
      )
    },
    profile() {
      this.$router.push({ name: 'profile' })
    },
    navigateDashboard() {
      if (this.$router.currentRoute.name != 'dashboard') {
        this.$router.replace({ name: 'dashboard' })
      }
    },
  },
}
</script>
