<template>
  <mdb-container fluid>
    <mdb-container>
      <mdb-row>
        <mdb-col col="12" class="d-flex justify-content-between">
          <mdb-btn color="secondary" @click="routeBack" size="sm">
            <mdb-icon icon="arrow-left" /> {{ $t('back') }}
          </mdb-btn>
          <mdb-btn color="success" @click="saveContent" size="sm">
            <mdb-icon icon="save" /> {{ $t('save') }}
          </mdb-btn>
        </mdb-col>
      </mdb-row>
      <mdb-card>
        <mdb-card-body class="d-flex justify-content-center">
          <vue-file-toolbar-menu
            v-for="(content, index) in bars_content"
            :key="'bar-' + index"
            :content="content"
          />
        </mdb-card-body>
      </mdb-card>
      <mdb-row>
        <mdb-col>
          <vue-document-editor :content.sync="content" />
        </mdb-col>
      </mdb-row>
    </mdb-container>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbCard,
  mdbCardBody,
} from 'mdbvue'
import VueDocumentEditor from 'vue-document-editor'
import VueFileToolbarMenu from 'vue-file-toolbar-menu'
import { ajax } from '../../helpers/ajax'

export default {
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    VueFileToolbarMenu,
    mdbBtn,
    mdbIcon,
    mdbCard,
    mdbCardBody,
    VueDocumentEditor,
  },
  computed: {
    bars_content() {
      return [
        [
          {
            icon: 'format_align_left',
            title: 'Align left',
            hotkey: this.isMacLike ? 'shift+command+l' : 'ctrl+shift+l',
            click: () => document.execCommand('justifyLeft'),
          },
          {
            icon: 'format_align_center',
            title: 'Align center',
            hotkey: this.isMacLike ? 'shift+command+e' : 'ctrl+shift+e',
            click: () => document.execCommand('justifyCenter'),
          },
          {
            icon: 'format_align_right',
            title: 'Align right',
            hotkey: this.isMacLike ? 'shift+command+r' : 'ctrl+shift+r',
            click: () => document.execCommand('justifyRight'),
          },
          {
            icon: 'format_align_justify',
            title: 'Justify content',
            hotkey: this.isMacLike ? 'shift+command+j' : 'ctrl+shift+j',
            click: () => document.execCommand('justifyFull'),
          },
          { is: 'separator' },
          {
            icon: 'format_bold',
            title: 'Bold',
            hotkey: this.isMacLike ? 'command+b' : 'ctrl+b',
            click: () => document.execCommand('bold'),
          },
          {
            icon: 'format_italic',
            title: 'Italic',
            hotkey: this.isMacLike ? 'command+i' : 'ctrl+i',
            click: () => document.execCommand('italic'),
          },
          {
            icon: 'format_underline',
            title: 'Underline',
            hotkey: this.isMacLike ? 'command+u' : 'ctrl+u',
            click: () => document.execCommand('underline'),
          },
          {
            icon: 'format_strikethrough',
            title: 'Strike through',
            click: () => document.execCommand('strikethrough'),
          },
          {
            is: 'button-color',
            type: 'compact',
            menu_class: 'align-center',
            stay_open: false,
            color: this.color,
            update_color: (new_color) => {
              // this.color = new_color
              document.execCommand('foreColor', false, new_color.hex8)
            },
          },
          { is: 'separator' },
          {
            icon: 'format_list_numbered',
            title: 'Numbered list',
            click: () => document.execCommand('insertOrderedList'),
          },
          {
            icon: 'format_list_bulleted',
            title: 'Bulleted list',
            click: () => document.execCommand('insertUnorderedList'),
          },
          { is: 'separator' },
          {
            icon: 'format_indent_increase',
            title: 'Increase indent',
            click: () => document.execCommand('indent'),
          },
          {
            icon: 'format_indent_decrease',
            title: 'Decrease indent',
            click: () => document.execCommand('outdent'),
          },
          { is: 'separator' },
          {
            html: '<b>H1</b>',
            title: 'Header 1',
            click: () => document.execCommand('formatBlock', false, '<h1>'),
          },
          {
            html: '<b>H2</b>',
            title: 'Header 2',
            click: () => document.execCommand('formatBlock', false, '<h2>'),
          },
          {
            html: '<b>H3</b>',
            title: 'Header 3',
            click: () => document.execCommand('formatBlock', false, '<h3>'),
          },
          {
            icon: 'format_clear',
            text: 'Clear',
            title: 'Clear format',
            click() {
              document.execCommand('removeFormat')
              document.execCommand('formatBlock', false, '<div>')
            },
          },
        ],
      ]
    },
    isMacLike: () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
    is_touch_device: () =>
      'ontouchstart' in window || window.navigator.msMaxTouchPoints > 0,
  },
  data: () => ({
    loading: false,
    content: [''],
    color: '#2E2E2E',
    edit_mode: true,
    id: 0,
  }),
  methods: {
    saveContent() {
      this.loading = true
      ajax(
        {
          url: 'user/submit_text',
          data: { id: this.id, content: this.content },
          success: () => {
            this.$notify.success({
              message: this.$t('content_submitted'),
              position: 'top right',
              timeOut: 3000,
            })
            this.$router.back()
          },
        },
        this
      )
      this.loading = false
    },
    routeBack() {
      this.$router.back()
    },
  },
  mounted: function () {
    //get focus content
    const recipient_id = this.$route.params.recipient_id
    this.id = recipient_id
    const index = this.$store.state.recipients.findIndex(
      (recipient) => recipient.id == recipient_id
    )
    if (index >= 0 && this.$store.state.recipients[index].text) {
      this.content = JSON.parse(this.$store.state.recipients[index].text)
    }
  },
}
</script>
