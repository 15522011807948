import Store from '../store'

export const isLoggedIn = () => {
  const token = Store.state.access_token
  if (token) return true
  return false
}

export const validatePhoneNumber = (phone_number) => {
  if (phone_number.charAt(0) === '+') {
    return phone_number.substring(1, phone_number.length)
  }
  if (phone_number.charAt(0) === '0') {
    return `6${phone_number}`
  }
  if (phone_number.charAt(0) === '1') {
    return `60${phone_number}`
  }
  return phone_number
}
