<template>
  <mdb-container fluid class="p-0">
    <LandingLayout>
      <mdb-row v-if="!loading">
        <mdb-col md="4">
          <div
            :class="`img-${image_index}`"
            v-for="image_index in [1, 2, 3, 4, 5]"
            v-bind:key="image_index"
          >
            <img
              :src="require(`@/assets/landing_${image_index}.jpg`)"
              class="landing-img"
            />
          </div>
        </mdb-col>
        <mdb-col class="text-center py-5" md="8">
          <h3 class="h3-responsive">
            {{ $t('verification_greeting') }}
          </h3>
          <h5 class="h5-responsive">
            {{
              $t('verification_identify_user_1', { name: fullname, type: type })
            }}
          </h5>
          <h6 class="mb-5">
            {{ $t('verification_identify_user_2') }}
          </h6>
          <mdb-btn @click="loginRegister" size="sm" color="primary"
            >Login / Register</mdb-btn
          >
        </mdb-col>
      </mdb-row>
      <mdb-row v-if="step == 1" class="pt-5 mt-5">
        <mdb-col md="4" offsetMd="4">
          <h4 class="h4-responsive text-center">
            {{ $t($route.params.type) }} for {{ username }} had been verified!
          </h4>
          <mdb-view
            :src="require('@/assets/verified.jpg')"
            alt="verified image"
          >
            <a>
              <mdb-mask overlay="white-slight"></mdb-mask>
            </a>
          </mdb-view>
        </mdb-col>
      </mdb-row>
    </LandingLayout>
    <login-register-modal-vue
      :visible="login_register_modal"
      :close="closeModal"
      :email="email"
      :preventNavigate="true"
    />
    <Loading v-if="loading" />
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbBtn } from 'mdbvue'
import { isLoggedIn } from '../helpers/common'
import LandingLayout from '../components/LandingLayout.vue'
import Loading from '../components/util/Loading.vue'
import LoginRegisterModalVue from '../components/user/LoginRegisterModal.vue'

export default {
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    Loading,
    mdbBtn,
    LandingLayout,
    LoginRegisterModalVue,
  },
  data: () => ({
    login_register_modal: false,
    loading: true,
    fullname: '',
    email: '',
    step: 0,
    type: '',
    verification_code: '',
  }),
  methods: {
    loginRegister() {
      this.login_register_modal = true
    },
    closeModal() {
      this.login_register_modal = false
      if (isLoggedIn()) this.verifyOwner()
    },
    async getData() {
      this.loading = true
      const url =
        this.type == 'recipient'
          ? 'user/recipient_verification'
          : 'user/trustee_verification'

      await this.$ajax(
        {
          url: `${url}?code=${this.verification_code}`,
          method: 'GET',
          success: (result) => {
            this.fullname = result.data.fullname
            this.email = result.data.email
            if (isLoggedIn()) this.verifyOwner()
          },
          fail: () => {
            this.$router.replace({ name: 'landing' })
          },
          isShowError: true,
        },
        this
      )
      this.loading = false
    },
    async verifyOwner() {
      this.loading = true
      const url =
        this.type == 'recipient'
          ? 'user/activate_recipient_verification'
          : 'user/activate_trustee_verification'

      await this.$ajax(
        {
          url: url,
          method: 'PUT',
          data: {
            code: this.verification_code,
          },
          success: () => {
            this.$router.replace({ name: 'dashboard' })
          },
          fail: () => {
            this.$router.replace({ name: 'landing' })
          },
          isShowError: true,
        },
        this
      )
      this.loading = false
    },
  },
  mounted: function () {
    const { type, verification_code } = this.$route.params
    if (!type || !verification_code) this.$router.replace({ name: 'landing' })
    this.type = type
    this.verification_code = verification_code
    this.getData()
  },
}
</script>

<style scoped>
.landing-img {
  height: auto;
  width: 250px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
}
.img-1 {
  position: absolute;
  top: 20px;
  left: 15px;
  z-index: 1;
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
.img-2 {
  position: absolute;
  top: 120px;
  left: 100px;
  z-index: 2;
  -ms-transform: rotate(7deg);
  transform: rotate(7deg);
}
.img-3 {
  position: absolute;
  top: 240px;
  left: 35px;
  z-index: 3;
  -ms-transform: rotate(-2deg);
  transform: rotate(-2deg);
}
.img-4 {
  position: absolute;
  top: 350px;
  left: 120px;
  z-index: 4;
  -ms-transform: rotate(7deg);
  transform: rotate(7deg);
}
.img-5 {
  position: absolute;
  top: 470px;
  z-index: 5;
  left: 25px;
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
}
</style>
