<template>
  <mdb-container fluid class="p-0 main-container">
    <mdb-container>
      <mdb-container fluid class="landing-container">
        <div class="logo-container">
          <img
            :src="require('@/assets/logo_horizontal_1024.png')"
            style="height: 40px; width: 150px"
          />
        </div>
        <slot></slot>
      </mdb-container>
    </mdb-container>
  </mdb-container>
</template>

<script>
import { mdbContainer } from 'mdbvue'
export default {
  name: 'landing_layout',
  components: {
    mdbContainer,
  },
}
</script>

<style scoped>
.landing-container {
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  /* margin: 20px; */
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
  display: block;
  padding: 1.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.logo-container {
  position: absolute;
  top: -20px;
  left: 75px;
  z-index: 5;
  background-color: #fff;
  padding-right: 1rem;
  padding-left: 1rem;
}
</style>
