<template>
  <mdb-modal @show="onShow" :show="visible" @close="closeModal">
    <mdb-modal-header>
      <mdb-modal-title>{{ $t('upload_proof') }}</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <mdb-row class="mb-3">
        <mdb-col col="12">
          <mdb-file-input
            img
            accept="image/jpeg, image/png, image/tiff"
            :src="getAttachment()"
            sm
            btnColor="primary"
            @getValue="uploadAttachment"
          />
        </mdb-col>
      </mdb-row>
    </mdb-modal-body>
    <mdb-modal-footer>
      <mdb-btn size="sm" @click="submitAttachment" color="primary"
        >Submit</mdb-btn
      >
    </mdb-modal-footer>
    <Loading v-if="loading" />
  </mdb-modal>
</template>

<script>
import {
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbRow,
  mdbCol,
  mdbModalFooter,
  mdbFileInput,
  mdbBtn,
} from 'mdbvue'
import Loading from '../util/Loading.vue'
import { fileAjax } from '../../helpers/ajax'
import { APP_STORAGE_URL } from '../../helpers/config'
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    focus_trustee: {
      type: Object,
    },
  },
  components: {
    mdbModal,
    mdbRow,
    mdbCol,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbFileInput,
    mdbBtn,
    Loading,
  },
  data() {
    return {
      loading: false,
      attachment: null,
      id: 0,
    }
  },
  methods: {
    closeModal() {
      this.close()
    },
    setLoading(loading) {
      this.loading = loading
    },
    openAvatarPicker() {
      document.getElementById('avatar-upload').click()
    },
    getAttachment() {
      if (this.attachment) {
        return APP_STORAGE_URL + this.attachment
      }
      return require('@/assets/upload_placeholder.jpg')
    },
    uploadAttachment(file) {
      if (file.length == 0) {
        return false
      }
      this.loading = true
      fileAjax(
        {
          url: 'user/upload_attachment',
          data: file,
          success: (result) => {
            this.attachment = result.data
          },
          complete: () => (this.loading = false),
          isShowError: true,
        },
        this
      )
    },
    submitAttachment() {
      this.loading = true
      this.$ajax(
        {
          url: 'user/update_attachment',
          method: 'PUT',
          data: { id: this.id, attachment: this.attachment },
          success: () => {
            this.$notify.success({
              message: this.$t('attachment_upload_success'),
              position: 'top right',
              timeOut: 3000,
            })
            this.$emit('reload')
            this.close()
          },
          complete: () => (this.loading = false),
        },
        this
      )
    },
    onShow() {
      this.id = this.focus_trustee.id
      this.attachment = this.focus_trustee.attachment
    },
  },
}
</script>

<style scoped>
.avatar-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  width: 200px;
  border-radius: 200px;
}
</style>
