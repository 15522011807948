<template>
  <div>
    <mdb-modal @shown="componentDidMount" :show="visible" @close="closeModal">
      <mdb-modal-header>
        <mdb-modal-title>{{
          form.id == 0 ? 'Add Admin' : 'Edit Admin'
        }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-container>
          <mdb-row>
            <mdb-col col="12">
              <mdb-input
                :disabled="!!form.id"
                v-model="form.username"
                label="Username"
                size="sm"
              />
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col col="12">
              <mdb-input
                v-model="form.phone_number"
                label="Phone Number"
                size="sm"
                @blur="validatePhoneFormat"
              />
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col col="12">
              <mdb-input
                type="password"
                v-model="form.password"
                label="Password"
                size="sm"
              />
            </mdb-col>
          </mdb-row>
        </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-spinner v-if="loading" />
        <mdb-btn v-if="!loading" @click="submitAdminUser" color="primary">{{
          form.id ? 'Edit' : 'Add'
        }}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbSpinner,
} from 'mdbvue'
import { ajax } from '../../helpers/ajax'
import { validatePhoneNumber } from '../../helpers/common'

export default {
  components: {
    mdbModal,
    mdbSpinner,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbBtn,
    mdbContainer,
    mdbRow,
    mdbCol,
  },
  props: ['visible', 'close', 'focus_user'],
  data: () => ({
    loading: false,
    form: {
      id: 0,
      username: '',
      name: '',
      phone_number: '',
      password: '',
    },
  }),
  methods: {
    closeModal() {
      this.form = {
        id: 0,
        username: '',
        phone_number: '',
        password: '',
      }
      this.loading = false
      this.close()
    },
    componentDidMount() {
      if (this.focus_user) {
        this.form = { ...this.focus_user }
      }
    },
    validatePhoneFormat() {
      this.form.phone_number = validatePhoneNumber(this.form.phone_number)
    },
    submitAdminUser() {
      if (this.loading) {
        return false
      }
      this.loading = true
      ajax(
        {
          url: 'admin/submit_admin',
          data: { ...this.form },
          complete: () => (this.loading = false),
          success: () => {
            this.$emit('getData')
            this.$notify.success({
              message: this.form.id == 0 ? 'Admin Added' : 'Admin Updated',
              position: 'top right',
              timeOut: 5000,
            })
            this.close()
          },
        },
        this
      )
    },
  },
}
</script>
