export const relationshipOptions = [
  {
    text: 'Family',
    value: 'family',
  },
  {
    text: 'Friend',
    value: 'friend',
  },
  {
    text: 'Sibling',
    value: 'sibling',
  },
]
