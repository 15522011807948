import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import en from './i18n/en.json'
import { ajax } from './helpers/ajax'

Vue.config.productionTip = false
const plugin = {
  install() {
    Vue.prototype.$ajax = ajax
  },
}
import Notify from 'mdbvue/lib/components/Notify'
import './helpers/veeValidateRules.js'
Vue.use(Notify)
Vue.use(VueI18n)
Vue.use(plugin)

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: en,
  },
})
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
