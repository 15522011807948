<template>
  <AppNavigation>
    <mdb-container fluid>
      <h1 class="h1-responsive">Trustee Submissions</h1>
      <mdb-row>
        <mdb-col col="12">
          <mdb-tbl responsive striped>
            <mdb-tbl-head>
              <tr>
                <th>#</th>
                <th width="20%">User Info</th>
                <th width="60%">Trustee's Proof</th>
                <th width="15%">Action</th>
              </tr>
            </mdb-tbl-head>
            <mdb-tbl-body>
              <tr v-for="(user, index) in results" :key="user.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  <div v-if="user.username">
                    <mdb-icon far icon="envelope" /> {{ user.username }}
                  </div>
                  <div>
                    <mdb-icon far icon="user" /> {{ user.given_name }}
                    {{ user.surname }}
                  </div>
                  <div v-if="user.phone_number">
                    <mdb-icon icon="mobile-alt" /> {{ user.phone_number }}
                  </div>
                </td>
                <td>
                  <div v-for="trustee in user.trustees" v-bind:key="trustee.id">
                    <div v-if="trustee.attachment" class="d-flex flex-row">
                      <img
                        @click="showLightbox(trustee.attachment)"
                        :src="getAttachment(trustee.attachment)"
                        height="100"
                        width="100"
                        class="img-attachment"
                      />
                      <div class="d-flex flex-column ml-3">
                        <div>
                          {{ trustee.email }}
                          <mdb-icon
                            v-if="trustee.verified_at"
                            far
                            color="success"
                            icon="check-circle"
                          />
                          <mdb-icon
                            v-if="!trustee.verified_at"
                            far
                            color="grey"
                            icon="check-circle"
                          />
                        </div>
                        <div>{{ trustee.relationship }}</div>
                        <div>
                          <mdb-btn
                            @click="confirmDeleteAttachment(trustee)"
                            color="danger"
                            size="sm"
                            class="my-0"
                          >
                            <mdb-icon icon="trash" />
                          </mdb-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="d-flex flex-row">
                  <mdb-btn
                    @click="showReleaseUser(user)"
                    color="success"
                    size="sm"
                    class="my-0"
                  >
                    <mdb-icon far icon="check-circle" />
                  </mdb-btn>
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <mdb-lightbox
      :imgs="imgs"
      :visible="show_lightbox"
      @hide="show_lightbox = false"
    ></mdb-lightbox>
    <ConfirmationModalVue
      :visible="confirmation_delete_modal"
      :close="closeModal"
      title="Delete trustee attachment"
      :submit="deleteAttachment"
    />
    <ConfirmationModalVue
      :visible="confirmation_approve_modal"
      :close="closeModal"
      title="Approve Release User"
      text="This action cannot reversible"
      :submit="releaseUser"
    />
    <Loading v-if="loading" />
  </AppNavigation>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblBody,
  mdbLightbox,
  mdbTblHead,
  mdbIcon,
  mdbBtn,
} from 'mdbvue'
import AppNavigation from '../../components/admin/AppNavigation.vue'
import { APP_STORAGE_URL } from '../../helpers/config'
import Loading from '../../components/util/Loading.vue'
import ConfirmationModalVue from '../../components/util/ConfirmationModal.vue'

export default {
  name: 'trustee_submit',
  components: {
    mdbContainer,
    AppNavigation,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbBtn,
    mdbIcon,
    mdbLightbox,
    Loading,
    ConfirmationModalVue,
  },
  data: () => ({
    loading: false,
    results: [],
    imgs: [],
    show_lightbox: false,
    confirmation_delete_modal: false,
    confirmation_approve_modal: false,
    focus_id: 0,
  }),
  methods: {
    closeModal() {
      this.confirmation_delete_modal = false
      this.confirmation_approve_modal = false
    },
    async getData() {
      this.loading = true
      await this.$ajax(
        {
          url: 'admin/get_user_trustees',
          method: 'GET',
          isShowError: true,
          success: (result) => {
            this.results = this.results.concat(result.data)
          },
        },
        this
      )
      this.loading = false
    },
    getAttachment(attachment) {
      return APP_STORAGE_URL + attachment
    },
    showLightbox(attachment) {
      this.show_lightbox = true
      this.imgs[0] = APP_STORAGE_URL + attachment
    },
    confirmDeleteAttachment(trustee) {
      this.focus_id = trustee.id
      this.confirmation_delete_modal = true
    },
    showReleaseUser(user) {
      this.focus_id = user.id
      this.confirmation_approve_modal = true
    },
    deleteAttachment() {
      this.loading = true
      this.$ajax(
        {
          url: `admin/delete_attachment?id=${this.focus_id}`,
          method: 'delete',
          isShowError: true,
          success: () => {
            this.results = []
            this.getData()
          },
          complete: () => (this.loading = false),
        },
        this
      )
    },
    releaseUser() {
      this.loading = true
      this.$ajax(
        {
          url: `admin/approve_release`,
          data: { id: this.focus_id },
          method: 'put',
          isShowError: true,
          success: () => {
            this.results = []
            this.getData()
          },
          complete: () => (this.loading = false),
        },
        this
      )
    },
  },
  mounted: function () {
    this.getData()
  },
}
</script>

<style scoped>
.img-attachment {
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
