<template>
  <mdb-side-nav-2
    v-model="show"
    :data="navigation"
    :over="over"
    @setOffset="togglerOffset = $event"
    v-mdb-resize:start="checkWidth"
  >
    <div slot="header">
      <div class="d-flex flex-center p-2">
        <img
          :src="require('@/assets/logo_square.png')"
          class="img-fluid"
          style="height: 150px; width: 150px"
        />
      </div>
    </div>
    <mdb-navbar slot="nav" position="top" color="indigo" dark :toggler="false">
      <mdb-navbar-nav left>
        <mdb-icon
          :style="{
            paddingLeft: togglerOffset + 'px',
            transition: 'padding-left .3s linear',
          }"
          icon="bars"
          color="dark-grey-text"
          size="lg"
          @click.native="show = !show"
        />
      </mdb-navbar-nav>
      <mdb-navbar-nav class="nav-flex-icons" right>
        <mdb-dropdown tag="li" class="nav-item">
          <mdb-dropdown-toggle
            tag="a"
            navLink
            color="indigo"
            slot="toggle"
            waves-fixed
            ><mdb-icon icon="user" />
            {{ this.$store.state.user.username }}abc</mdb-dropdown-toggle
          >
          <mdb-dropdown-menu>
            <mdb-dropdown-item @click="changePassword"
              >Change Password</mdb-dropdown-item
            >
            <mdb-dropdown-item @click="logout">Logout</mdb-dropdown-item>
          </mdb-dropdown-menu>
        </mdb-dropdown>
      </mdb-navbar-nav>
    </mdb-navbar>
    <div slot="main" style="height: 85vh; margin-top: 5rem">
      <slot></slot>
    </div>
  </mdb-side-nav-2>
</template>

<script>
import moment from 'moment'
import {
  mdbNavbar,
  mdbNavbarNav,
  mdbSideNav2,
  mdbIcon,
  mdbDropdownItem,
  mdbDropdown,
  mdbDropdownMenu,
  mdbDropdownToggle,
  waves,
  mdbResize,
} from 'mdbvue'

export default {
  name: 'DoubleNavigationPagev1',
  components: {
    mdbNavbar,
    mdbDropdownItem,
    mdbNavbarNav,
    mdbSideNav2,
    mdbIcon,
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
  },
  data() {
    return {
      show: true,
      over: false,
      togglerOffset: 0,
      navigation: [],
    }
  },
  methods: {
    convertTime(date) {
      return moment(date).format('DD/MM/YYYY h:mm a')
    },
    checkWidth() {
      this.over = window.innerWidth < 900
    },
    logout() {
      localStorage.clear()
      window.location.href = '/'
    },
    changePassword() {
      this.$router.push({ name: 'admin_change_password' })
    },
  },
  mixins: [waves],
  directives: {
    mdbResize,
  },
  mounted: function () {
    this.navigation.push({
      name: 'Dashboard',
      icon: 'chart-bar',
      to: '/admin/dashboard',
    })
    this.navigation.push({
      name: 'Trustee Submit',
      icon: 'file-alt',
      to: '/admin/trustee_submit',
    })
    this.navigation.push({
      name: 'Admins',
      icon: 'users',
      to: '/admin/admins',
    })
    // this.navigation.push({
    //   name: 'App Configurations',
    //   icon: 'shopping-cart',
    //   children: [
    //     {
    //       name: 'App Version Control',
    //       to: '/app_version_control',
    //     },
    //   ],
    // })
  },
}
</script>

<style scoped>
.navbar i {
  cursor: pointer;
  color: white;
}
</style>
