<template>
  <mdb-modal :show="visible" @close="onClose">
    <mdb-modal-header>
      <mdb-modal-title
        >{{ title ? title : $t('confirmation_title') }}?</mdb-modal-title
      >
    </mdb-modal-header>
    <mdb-modal-body v-if="text">
      <p class="mx-5 text-center">{{ text }}</p>
    </mdb-modal-body>
    <mdb-modal-footer>
      <mdb-btn @click="onSubmit" color="primary">Confirm</mdb-btn>
      <mdb-btn @click="onClose" color="danger">Close</mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import {
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalFooter,
  mdbBtn,
  mdbModalBody,
} from 'mdbvue'

export default {
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalFooter,
    mdbBtn,
    mdbModalBody,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    close: {
      type: Function,
      require: true,
    },
    title: {
      type: String,
    },
    submit: { type: Function, require: true },
    text: { type: String },
  },
  methods: {
    onSubmit() {
      this.submit()
      this.close()
    },
    onClose() {
      this.close()
    },
  },
}
</script>

<style scoped></style>
