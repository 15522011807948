<template>
  <mdb-container>
    <validation-observer slim ref="loginForm">
      <mdb-row>
        <mdb-col col="12">
          <validation-provider
            :name="$t('email')"
            rules="required|email"
            v-slot="{ errors, touched }"
            slim
          >
            <mdb-input
              :label="$t('email')"
              :disabled="!!email"
              size="sm"
              v-model="login_form.username"
              :invalidFeedback="errors[0]"
              :isValid="!!!errors[0]"
              :validation="touched"
            />
          </validation-provider>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col col="12">
          <validation-provider
            :name="$t('password')"
            rules="required"
            v-slot="{ errors, touched }"
            slim
          >
            <mdb-input
              :label="$t('password')"
              size="sm"
              type="password"
              v-model="login_form.password"
              :invalidFeedback="errors[0]"
              :isValid="!!!errors[0]"
              :validation="touched"
            />
          </validation-provider>
        </mdb-col>
      </mdb-row>
      <mdb-row class="mb-3">
        <mdb-col col="12" class="justify-content-center d-flex">
          <a class="small" @click="navigateForgotPassword"
            >{{ $t('forgot_password') }}?</a
          >
        </mdb-col>
      </mdb-row>
      <mdb-row class="mb-3">
        <mdb-col col="12">
          <mdb-btn @click="login" block color="primary" size="sm">{{
            $t('login')
          }}</mdb-btn>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col col="12" class="text-center">
          <p class="small">
            {{ $t('not_a_member') }}?
            <a
              href="#"
              class="auth-modal-toggle"
              @click="register"
              data-auth-modal-tab="sign-up"
              >{{ $t('register') }}</a
            >
          </p>
        </mdb-col>
      </mdb-row>
    </validation-observer>
  </mdb-container>
</template>

<script>
import { mdbBtn, mdbContainer, mdbRow, mdbInput, mdbCol } from 'mdbvue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ajax } from '../../helpers/ajax'
export default {
  components: {
    mdbBtn,
    mdbContainer,
    mdbCol,
    mdbRow,
    mdbInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    preventNavigate: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      login_form: {
        username: '',
        password: '',
      },
    }
  },
  methods: {
    async login() {
      const success = await this.$refs.loginForm.validate()
      if (!success) {
        return false
      }
      this.$emit('loading', true)
      await ajax(
        {
          url: 'auth/login',
          data: {
            ...this.login_form,
          },
          success: (res) => {
            this.$store.dispatch('setUser', res.data.user)
            this.$store.dispatch('setAccessToken', res.data.access)
            this.$emit('close')
            if (!this.preventNavigate)
              this.$router.replace({ name: 'dashboard' })
          },
          isShowError: true,
        },
        this
      )
      this.$emit('loading', false)
    },
    register() {
      this.$emit('register')
    },
    navigateForgotPassword() {
      this.$router.push({ name: 'forgot_password' })
      this.$emit('close')
    },
  },
  mounted: function () {
    if (this.email) this.login_form.username = this.email
  },
}
</script>
