<template>
  <AppNavigation>
    <mdb-container fluid>
      <h1 class="h1-responsive">Users</h1>
      <mdb-row>
        <mdb-col col="12">
          <mdb-tbl responsive striped>
            <mdb-tbl-head>
              <tr>
                <th>#</th>
                <th width="20%">Info</th>
                <th width="25%">Recipients</th>
                <th width="25%">Trustees</th>
                <th width="15%">Last Login</th>
                <th width="10%">Action</th>
              </tr>
            </mdb-tbl-head>
            <mdb-tbl-body>
              <tr v-for="(user, index) in users" :key="user.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  <div v-if="user.username">
                    <mdb-icon far icon="envelope" /> {{ user.username }}
                    <mdb-icon
                      far
                      :color="user.is_active ? `success` : `grey`"
                      icon="check-circle"
                    />
                  </div>
                  <div>
                    <mdb-icon far icon="user" /> {{ user.given_name }}
                    {{ user.surname }}
                  </div>
                  <div v-if="user.phone_number">
                    <mdb-icon icon="mobile-alt" /> {{ user.phone_number }}
                  </div>
                  <div v-if="user.phone_number">
                    <mdb-icon icon="birthday-cake" /> {{ user.dob }}
                  </div>
                </td>
                <td>
                  <div
                    v-for="recipient in user.recipients"
                    :key="recipient.id"
                    class="d-flex flex-row justify-content-between"
                  >
                    <div class="d-flex flex-row align-items-center">
                      {{ recipient.email }} &nbsp;
                      <mdb-icon
                        v-if="recipient.verified_at"
                        far
                        color="success"
                        icon="check-circle"
                      />
                      <mdb-icon
                        v-if="!recipient.verified_at"
                        far
                        color="grey"
                        icon="check-circle"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    class="d-flex flex-row align-items-center"
                    v-for="trustee in user.trustees"
                    :key="trustee.id"
                  >
                    {{ trustee.email }} &nbsp;
                    <mdb-icon
                      v-if="trustee.verified_at"
                      far
                      color="success"
                      icon="check-circle"
                    />
                    <mdb-icon
                      v-if="!trustee.verified_at"
                      far
                      color="grey"
                      icon="check-circle"
                    />
                  </div>
                </td>
                <td>
                  {{ momentFormat(user.last_login) }}
                </td>
                <td>
                  <mdb-btn
                    @click="openConfirmationModal(user)"
                    color="danger"
                    size="sm"
                    class="my-0"
                    ><mdb-icon far icon="trash-alt" /> Ban</mdb-btn
                  >
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
        </mdb-col>
      </mdb-row>
      <ConfirmationModalVue
        :visible="confirmation_modal"
        :close="closeModal"
        :submit="deleteUser"
      />
      <Loading v-if="loading" />
    </mdb-container>
  </AppNavigation>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblBody,
  mdbTblHead,
  mdbIcon,
  mdbBtn,
} from 'mdbvue'
import AppNavigation from '../../components/admin/AppNavigation.vue'
import { ajax } from '../../helpers/ajax'
import { datetimeFormat } from '../../helpers/moment'
import ConfirmationModalVue from '../../components/util/ConfirmationModal.vue'
import Loading from '../../components/util/Loading.vue'

export default {
  components: {
    mdbContainer,
    AppNavigation,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbIcon,
    mdbBtn,
    ConfirmationModalVue,
    Loading,
  },
  data: () => ({
    loading: false,
    users: [],
    recipient_text_modal: false,
    focus_text: [],
    confirmation_modal: false,
    focus_user: null,
  }),
  methods: {
    async getData() {
      this.loading = true
      await ajax(
        {
          url: 'admin/get_users',
          method: 'GET',
          isShowError: true,
          success: (result) => {
            this.users = result.data
          },
        },
        this
      )
      this.loading = false
    },
    momentFormat(datetime) {
      return datetimeFormat(datetime)
    },
    showText(recipient, user) {
      this.$router.push({
        name: 'admin_recipient_text',
        params: { recipient: recipient, user: user },
      })
    },
    openConfirmationModal(user) {
      this.confirmation_modal = true
      this.focus_user = user
    },
    closeModal() {
      this.confirmation_modal = false
      this.focus_user = null
    },
    async deleteUser() {
      this.loading = true
      await ajax(
        {
          url: 'admin/delete_user',
          data: { id: this.focus_user.id },
          method: 'PUT',
          success: () => {
            this.users = []
            this.getData()
          },
        },
        this
      )
      this.loading = false
    },
  },
  mounted: function () {
    this.getData()
  },
}
</script>
