<template>
  <mdb-navbar light color="grey lighten-5">
    <mdb-container>
      <mdb-navbar-brand href="/" @click.native="navigateHome">
        <img
          :src="require('@/assets/logo_horizontal_1024.png')"
          style="height: 25px; width: 100px"
        />
      </mdb-navbar-brand>
      <mdb-navbar-toggler>
        <mdb-navbar-nav right>
          <mdb-btn color="white" @click="navigateLogin" size="sm"
            >{{ $t('login') }} / {{ $t('register') }}</mdb-btn
          >
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-container>
  </mdb-navbar>
</template>

<script>
import {
  mdbContainer,
  mdbNavbar,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavbarBrand,
  mdbBtn,
} from 'mdbvue'
export default {
  name: 'landing',
  components: {
    mdbContainer,
    mdbNavbar,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavbarBrand,
    mdbBtn,
  },
  methods: {
    navigateLogin() {
      this.$emit('login')
    },
    navigateHome() {
      window.location.href = '/'
    },
  },
}
</script>
