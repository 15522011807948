<template>
  <mdb-container fluid class="p-0">
    <navbar-public-vue />
    <mdb-container>
      <div class="row mt-5">
        <div class="col-md-12">
          <h1 class="h1 text-center my-5">Hano Privacy Policy</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4 class="h4 mt-3">PRIVACY NOTICE</h4>
          <p class="text-left">
            This Privacy Notice (“Notice”) describes how Hano Technology and its
            respective subsidiaries, associate companies and jointly controlled
            entities ("Hano Technology") use your Personal Data.
          </p>
          <h4 class="h4 mt-5">Owner and Data Controller</h4>
          <h4 class="h4 mt-5">Types of Data collected</h4>
          <p class="text-left">
            Among the types of Personal Data that Hano Application collects, by
            itself or through third parties, there are: first name; last name;
            gender; date of birth; phone number; city. Complete details on each
            type of Personal Data collected are provided in the dedicated
            sections of this privacy policy or by specific explanation texts
            displayed prior to the Data collection. Personal Data may be freely
            provided by the User, or, in case of Usage Data, collected
            automatically when using Hano Application.
          </p>
          <p class="text-left">
            Unless specified otherwise, all Data requested by Hano Application
            is mandatory and failure to provide this Data may make it impossible
            for Hano Application to provide its services. In cases where Hano
            Application specifically states that some Data is not mandatory,
            Users are free not to communicate this Data without consequences to
            the availability or the functioning of the Service.
          </p>
          <p class="text-left">
            Users who are uncertain about which Personal Data is mandatory are
            welcome to contact the Owner. Any use of Cookies – or of other
            tracking tools – by Hano Application or by the owners of third-party
            services used by Hano Application serves the purpose of providing
            the Service required by the User, in addition to any other purposes
            described in the present document and in the Cookie Policy, if
            available.
          </p>
          <p class="text-left">
            Users are responsible for any third-party Personal Data obtained,
            published or shared through Hano Application and confirm that they
            have the third party's consent to provide the Data to the Owner.
          </p>
          <h4 class="h4 mt-4">Mode and place of processing the Data</h4>
          <h4 class="h4 mt-5">Methods of processing</h4>
          <p class="text-left">
            The Owner takes appropriate security measures to prevent
            unauthorized access, disclosure, modification, or unauthorized
            destruction of the Data.
          </p>
          <p class="text-left">
            The Data processing is carried out using computers and/or IT enabled
            tools, following organizational procedures and modes strictly
            related to the purposes indicated. In addition to the Owner, in some
            cases, the Data may be accessible to certain types of persons in
            charge, involved with the operation of Hano Application
            (administration, sales, marketing, legal, system administration) or
            external parties (such as third-party technical service providers,
            mail carriers, hosting providers, IT companies, communications
            agencies) appointed, if necessary, as Data Processors by the Owner.
            The updated list of these parties may be requested from the Owner at
            any time.
          </p>
          <h4 class="h4 mt-5">Legal basis of processing</h4>
          <p class="text-left">
            The Owner may process Personal Data relating to Users if one of the
            following applies:
          </p>

          <ul class="mt-1">
            <li class="my-2">
              Users have given their consent for one or more specific purposes.
              Note: Under some legislations the Owner may be allowed to process
              Personal Data until the User objects to such processing
              (“opt-out”), without having to rely on consent or any other of the
              following legal bases. This, however, does not apply, whenever the
              processing of Personal Data is subject to European data protection
              law;
            </li>
            <li class="my-2">
              provision of Data is necessary for the performance of an agreement
              with the User and/or for any pre-contractual obligations thereof;
            </li>
            <li class="my-2">
              processing is necessary for compliance with a legal obligation to
              which the Owner is subject;
            </li>
            <li class="my-2">
              processing is related to a task that is carried out in the public
              interest or in the exercise of official authority vested in the
              Owner;
            </li>
            <li class="my-2">
              processing is necessary for the purposes of the legitimate
              interests pursued by the Owner or by a third party.
            </li>
          </ul>
          <p class="text-left">
            In any case, the Owner will gladly help to clarify the specific
            legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </p>
          <h4 class="h4 mt-5">Place</h4>
          <p class="text-left">
            The Data is processed at the Owner's operating offices and in any
            other places where the parties involved in the processing are
            located.
          </p>
          <p class="text-left">
            Depending on the User's location, data transfers may involve
            transferring the User's Data to a country other than their own. To
            find out more about the place of processing of such transferred
            Data, Users can check the section containing details about the
            processing of Personal Data.
          </p>
          <p class="text-left">
            Users are also entitled to learn about the legal basis of Data
            transfers to a country outside the European Union or to any
            international organization governed by public international law or
            set up by two or more countries, such as the UN, and about the
            security measures taken by the Owner to safeguard their Data.
          </p>
          <p class="text-left">
            If any such transfer takes place, Users can find out more by
            checking the relevant sections of this document or inquire with the
            Owner using the information provided in the contact section.
          </p>
          <h4 class="h4 mt-5">Retention time</h4>
          <p class="text-left">
            Personal Data shall be processed and stored for as long as required
            by the purpose they have been collected for.
          </p>
          <p class="text-left">Therefore:</p>
          <ul class="mt-1">
            <li class="my-2">
              Personal Data collected for purposes related to the performance of
              a contract between the Owner and the User shall be retained until
              such contract has been fully performed.
            </li>
            <li class="my-2">
              Personal Data collected for the purposes of the Owner’s legitimate
              interests shall be retained as long as needed to fulfill such
              purposes. Users may find specific information regarding the
              legitimate interests pursued by the Owner within the relevant
              sections of this document or by contacting the Owner.
            </li>
          </ul>
          <p class="text-left">
            The Owner may be allowed to retain Personal Data for a longer period
            whenever the User has given consent to such processing, as long as
            such consent is not withdrawn. Furthermore, the Owner may be obliged
            to retain Personal Data for a longer period whenever required to do
            so for the performance of a legal obligation or upon order of an
            authority.
          </p>

          <p class="text-left">
            Once the retention period expires, Personal Data shall be deleted.
            Therefore, the right to access, the right to erasure, the right to
            rectification and the right to data portability cannot be enforced
            after expiration of the retention period.
          </p>

          <h4 class="h4 mt-5">The purposes of processing</h4>
          <p class="text-left">
            The Data concerning the User is collected to allow the Owner to
            provide its Services, as well as for the following purposes:
            Contacting the User.
          </p>
          <p class="text-left">
            Users can find further detailed information about such purposes of
            processing and about the specific Personal Data used for each
            purpose in the respective sections of this document.
          </p>

          <h4 class="h4 mt-5">
            Facebook permissions asked by Hano Application
          </h4>
          <p class="text-left">
            Hano Application may ask for some Facebook permissions allowing it
            to perform actions with the User's Facebook account and to retrieve
            information, including Personal Data, from it. This service allows
            Hano Application to connect with the User's account on the Facebook
            social network, provided by Facebook Inc.
          </p>
          <p class="text-left">
            For more information about the following permissions, refer to the
            Facebook permissions documentation and to the Facebook privacy
            policy.
          </p>
          <p class="text-left">The permissions asked are the following:</p>
          <h5 class="h4 mt-5">Basic information</h5>
          <p class="text-left">
            By default, this includes certain User’s Data such as id, name,
            picture, gender, and their locale. Certain connections of the User,
            such as the Friends, are also available. If the User has made more
            of their Data public, more information will be available.
          </p>
          <h5 class="h4 mt-5">Email</h5>
          <p class="text-left">
            Provides access to the User's primary email address.
          </p>

          <h4 class="h4 mt-5">
            Detailed information on the processing of Personal Data
          </h4>
          <p class="text-left">
            Personal Data is collected for the following purposes and using the
            following services
          </p>
          <h4 class="h4 mt-5">The rights of Users</h4>
          <p class="text-left">
            Users may exercise certain rights regarding their Data processed by
            the Owner.
          </p>
          <p class="text-left">
            In particular, Users have the right to do the following:
          </p>
          <ul class="mt-1">
            <li class="my-2">
              Withdraw their consent at any time. Users have the right to
              withdraw consent where they have previously given their consent to
              the processing of their Personal Data.
            </li>
            <li class="my-2">
              Object to processing of their Data. Users have the right to object
              to the processing of their Data if the processing is carried out
              on a legal basis other than consent. Further details are provided
              in the dedicated section below.
            </li>
            <li class="my-2">
              Access their Data. Users have the right to learn if Data is being
              processed by the Owner, obtain disclosure regarding certain
              aspects of the processing and obtain a copy of the Data undergoing
              processing.
            </li>
            <li class="my-2">
              Verify and seek rectification. Users have the right to verify the
              accuracy of their Data and ask for it to be updated or corrected.
            </li>
            <li class="my-2">
              Restrict the processing of their Data. Users have the right, under
              certain circumstances, to restrict the processing of their Data.
              In this case, the Owner will not process their Data for any
              purpose other than storing it.
            </li>
            <li class="my-2">
              Have their Personal Data deleted or otherwise removed. Users have
              the right, under certain circumstances, to obtain the erasure of
              their Data from the Owner.
            </li>
            <li class="my-2">
              Receive their Data and have it transferred to another controller.
              Users have the right to receive their Data in a structured,
              commonly used and machine readable format and, if technically
              feasible, to have it transmitted to another controller without any
              hindrance. This provision is applicable provided that the Data is
              processed by automated means and that the processing is based on
              the User's consent, on a contract which the User is part of or on
              pre-contractual obligations thereof.
            </li>
            <li class="my-2">
              Lodge a complaint. Users have the right to bring a claim before
              their competent data protection authority.
            </li>
          </ul>
          <h4 class="h4 mt-5">
            Details about the right to object to processing
          </h4>
          <p class="text-left">
            Where Personal Data is processed for a public interest, in the
            exercise of an official authority vested in the Owner or for the
            purposes of the legitimate interests pursued by the Owner, Users may
            object to such processing by providing a ground related to their
            particular situation to justify the objection.
          </p>
          <p class="text-left">
            Users must know that, however, should their Personal Data be
            processed for direct marketing purposes, they can object to that
            processing at any time without providing any justification. To
            learn, whether the Owner is processing Personal Data for direct
            marketing purposes, Users may refer to the relevant sections of this
            document.
          </p>
          <h5 class="h4 mt-5">How to exercise these rights</h5>
          <p class="text-left">
            Any requests to exercise User rights can be directed to the Owner
            through the contact details provided in this document. These
            requests can be exercised free of charge and will be addressed by
            the Owner as early as possible and always within one month.
          </p>
          <h4 class="h4 mt-5">
            Additional information about Data collection and processing
          </h4>
          <h5 class="h4 mt-5">Legal action</h5>
          <p class="text-left">
            The User's Personal Data may be used for legal purposes by the Owner
            in Court or in the stages leading to possible legal action arising
            from improper use of Hano Application or the related Services.
          </p>
          <p class="text-left">
            The User declares to be aware that the Owner may be required to
            reveal personal data upon request of public authorities.
          </p>
          <h5 class="h4 mt-5">Legal action</h5>
          <p class="text-left">
            Any requests to exercise User rights can be directed to the Owner
            through the contact details provided in this document. These
            requests can be exercised free of charge and will be addressed by
            the Owner as early as possible and always within one month.
          </p>
          <h5 class="h4 mt-5">
            Additional information about User's Personal Data
          </h5>
          <p class="text-left">
            In addition to the information contained in this privacy policy,
            Hano Application may provide the User with additional and contextual
            information concerning particular Services or the collection and
            processing of Personal Data upon request.
          </p>
          <h5 class="h4 mt-5">System logs and maintenance</h5>
          <p class="text-left">
            For operation and maintenance purposes, Hano Application and any
            third-party services may collect files that record interaction with
            Hano Application (System logs) use other Personal Data (such as the
            IP Address) for this purpose.
          </p>
          <h5 class="h4 mt-5">Information not contained in this policy</h5>
          <p class="text-left">
            More details concerning the collection or processing of Personal
            Data may be requested from the Owner at any time.
          </p>
          <p class="text-left">
            Please see the contact information at the beginning of this
            document.
          </p>
          <h5 class="h4 mt-5">How “Do Not Track” requests are handled</h5>
          <p class="text-left">
            Hano Application does not support “Do Not Track” requests.
          </p>
          <p class="text-left">
            To determine whether any of the third-party services it uses honor
            the “Do Not Track” requests, please read their privacy policies.
          </p>
          <h5 class="h4 mt-5">Changes to this privacy policy</h5>
          <p class="text-left">
            The Owner reserves the right to make changes to this privacy policy
            at any time by giving notice to its Users on this page and possibly
            within Hano Application and/or - as far as technically and legally
            feasible - sending a notice to Users via any contact information
            available to the Owner. It is strongly recommended to check this
            page often, referring to the date of the last modification listed at
            the bottom.
          </p>

          <p class="text-left">
            Should the changes affect processing activities performed on the
            basis of the User’s consent, the Owner shall collect new consent
            from the User, where required.
          </p>
        </div>
      </div>
    </mdb-container>
    <landing-footer-vue />
  </mdb-container>
</template>

<script>
import NavbarPublicVue from '../components/NavbarPublic.vue'
import LandingFooterVue from '../components/LandingFooter.vue'
import { mdbContainer } from 'mdbvue'
export default {
  components: {
    NavbarPublicVue,
    LandingFooterVue,
    mdbContainer,
  },
}
</script>
