<template>
  <div>
    <mdb-container v-if="register_step == 0">
      <validation-observer slim ref="registerVerificationForm">
        <mdb-row>
          <mdb-col col="12">
            <validation-provider
              :name="$t('email')"
              rules="required|email"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-input
                :label="$t('email')"
                size="sm"
                :disabled="!!email"
                v-model="register_form.username"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
      </validation-observer>
      <mdb-row class="mb-3">
        <mdb-col col="12">
          <mdb-btn
            @click="requestVerification(true)"
            block
            color="primary"
            size="sm"
            >{{ $t('request_verification') }}</mdb-btn
          >
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <mdb-container v-if="register_step == 1">
      <validation-observer slim ref="registerOtpForm">
        <mdb-row>
          <mdb-col col="12">
            <validation-provider
              :name="$t('verification_code')"
              rules="required|numeric|min_length:6|max_length:6"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-input
                :label="$t('verification_code')"
                size="sm"
                v-model="register_form.verification_code"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
      </validation-observer>
      <mdb-row class="mb-2">
        <mdb-col col="8">
          <mdb-btn @click="otpVerification" block color="primary" size="sm">{{
            $t('verify')
          }}</mdb-btn>
        </mdb-col>
        <mdb-col col="4">
          <mdb-btn
            :disabled="username_verification_timer != 0"
            @click="requestVerification(false)"
            block
            color="grey"
            size="sm"
            >{{
              username_verification_timer == 0
                ? $t('resend_code')
                : username_verification_timer
            }}</mdb-btn
          >
        </mdb-col>
      </mdb-row>
      <mdb-row class="mb-3">
        <mdb-col col="12" class="justify-content-center d-flex">
          <a class="small" @click="register_step = 0">{{ $t('back') }}</a>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <mdb-container v-if="register_step == 2">
      <validation-observer slim ref="registerForm">
        <mdb-row>
          <mdb-col col="12">
            <validation-provider
              :name="$t('email')"
              rules="required"
              v-slot="{ errors }"
              slim
            >
              <mdb-input
                :label="$t('email')"
                size="sm"
                v-model="register_form.username"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                disabled
                read-only
                :validation="true"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col col="6">
            <validation-provider
              :name="$t('given_name')"
              rules="required"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-input
                :label="$t('given_name')"
                size="sm"
                v-model="register_form.given_name"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
              />
            </validation-provider>
          </mdb-col>
          <mdb-col col="6">
            <validation-provider
              :name="$t('surname')"
              rules="required"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-input
                :label="$t('surname')"
                size="sm"
                v-model="register_form.surname"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col col="12">
            <validation-provider
              :name="$t('dob')"
              rules="required"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-date-picker
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
                v-model="register_form.dob"
                :label="$t('dob')"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col col="12">
            <validation-provider
              :name="$t('phone_number')"
              rules="required|numeric|max_length:12|min_length:10"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-input
                :label="$t('phone_number')"
                size="sm"
                v-model="register_form.phone_number"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
                @blur="validatePhoneFormat"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col col="12">
            <validation-provider
              :name="$t('password')"
              rules="required|min_length:8"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-input
                :label="$t('password')"
                size="sm"
                type="password"
                v-model="register_form.password"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col col="12">
            <validation-provider
              :name="$t('password_confirmation')"
              :rules="`required|confirmed:@${$t('password')}`"
              v-slot="{ errors, touched }"
              slim
            >
              <mdb-input
                :label="$t('password_confirmation')"
                size="sm"
                type="password"
                v-model="register_form.password_confirmation"
                :invalidFeedback="errors[0]"
                :isValid="!!!errors[0]"
                :validation="touched"
              />
            </validation-provider>
          </mdb-col>
        </mdb-row>
      </validation-observer>
      <mdb-row class="mb-3">
        <mdb-col col="12">
          <mdb-btn block @click="registerUser" color="success" size="sm">{{
            $t('register')
          }}</mdb-btn>
        </mdb-col>
      </mdb-row>
      <mdb-row class="mb-3">
        <mdb-col col="12" class="justify-content-center d-flex">
          <a class="small" @click="register_step = 0">{{ $t('back') }}</a>
        </mdb-col>
      </mdb-row>
    </mdb-container>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbContainer,
  mdbRow,
  mdbInput,
  mdbCol,
  mdbDatePicker,
} from 'mdbvue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ajax } from '../../helpers/ajax'
import { validatePhoneNumber } from '../../helpers/common'
import { getSecondDuration } from '../../helpers/moment'
export default {
  components: {
    mdbBtn,
    mdbContainer,
    mdbCol,
    mdbRow,
    mdbInput,
    ValidationProvider,
    ValidationObserver,
    mdbDatePicker,
  },
  props: {
    preventNavigate: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      register_step: 0,
      register_form: {
        username: '',
        verification_code: '',
        given_name: '',
        surname: '',
        password: '',
        password_confirmation: '',
        phone_number: '',
        otp_id: 0,
        dob: '',
      },
      username_verification_timer: 0,
      timer: null,
    }
  },
  methods: {
    async requestVerification(validation_required = false) {
      if (validation_required) {
        const success = await this.$refs.registerVerificationForm.validate()
        if (!success) {
          return false
        }
      }
      this.$emit('loading', true)
      await ajax(
        {
          url: 'auth/request_register_otp',
          data: { username: this.register_form.username },
          success: (res) => {
            const second = getSecondDuration(res.data)
            this.username_verification_timer = second
            clearInterval(this.timer)
            this.timer = setInterval(() => {
              if (this.username_verification_timer > 0) {
                this.username_verification_timer =
                  parseInt(this.username_verification_timer) - 1
              } else {
                clearInterval(this.timer)
              }
            }, 1000)
            validation_required
              ? this.$refs.registerVerificationForm.reset()
              : null
            this.register_step = 1
          },
          isShowError: true,
        },
        this
      )
      this.$emit('loading', false)
    },
    async otpVerification() {
      const success = await this.$refs.registerOtpForm.validate()
      if (!success) {
        return false
      }
      this.$emit('loading', true)
      await ajax(
        {
          url: 'auth/otp_verification',
          data: {
            username: this.register_form.username,
            otp: this.register_form.verification_code,
          },
          success: (res) => {
            this.register_step = 2
            this.register_form.otp_id = res.data
            clearInterval(this.timer)
            this.$refs.registerOtpForm.reset()
          },
          isShowError: true,
        },
        this
      )
      this.$emit('loading', false)
    },
    async registerUser() {
      const success = await this.$refs.registerForm.validate()
      if (!success) {
        return false
      }
      this.$emit('loading', true)
      await ajax(
        {
          url: 'auth/complete_registration',
          data: {
            ...this.register_form,
            otp: this.register_form.verification_code,
          },
          success: (res) => {
            this.$store.dispatch('setUser', res.data.user)
            this.$store.dispatch('setAccessToken', res.data.access)
            this.$emit('close')
            if (!this.preventNavigate)
              this.$router.replace({ name: 'dashboard' })
          },
          isShowError: true,
        },
        this
      )
      this.$emit('loading', false)
    },
    validatePhoneFormat() {
      this.register_form.phone_number = validatePhoneNumber(
        this.register_form.phone_number
      )
    },
  },
  mounted: function () {
    if (this.email) this.register_form.username = this.email
  },
}
</script>
